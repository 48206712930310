import { Transition } from "@headlessui/react";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.open - The root object
 * @example
 */
const DropdownTransition = ({ children, open }) => (
  <Transition
    as="div"
    enter="transition duration-100 ease-out"
    enterFrom="transform scale-95 opacity-0"
    enterTo="transform scale-100 opacity-100"
    leave="transition duration-75 ease-out"
    leaveFrom="transform scale-100 opacity-100"
    leaveTo="transform scale-95 opacity-0"
    show={open}
  >
    {children}
  </Transition>
);

export default DropdownTransition;
