import cn from "classnames";
import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter.js";
import {
  formatArea, formatDate, formatEuro
} from "~/src/modules/formatters.js";
import { formatDecimalExactPrecision } from "~/src/modules/formatters.new.js";

import NewCompanyModal from "~/src/features/modals/new-company-modal/index.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";

import EditTransactionSlide from "./edit-transaction-slide.jsx";
import { handleDelete } from "./handlers.js";
import NewTransactionSlide from "./new-transaction-slide.jsx";
import TransactionSettingsSlide from "./transaction-settings-slide/index.jsx";

/**
 *
 * @example
 */
const ProjectTransactions = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    hide: hideNewCompany, show: showNewCompany, visible: newCompanyVisible
  } = useModal();
  const {
    hide: hideNew, show: showNew, visible: newVisible
  } = useModal();
  const {
    data: editData, hide: hideEdit, show: showEdit, visible: editVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();
  const {
    hide: hideSettings, show: showSettings, visible: settingsVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  const hasBaurecht = project?.transactions.filter((transaction) => transaction.transaction_category?.code === "baurecht").length > 0 || false;

  return (
    <div>
      <NewCompanyModal hide={hideNewCompany} visible={newCompanyVisible} />

      <NewTransactionSlide hide={hideNew} projectId={id} showNewCompany={showNewCompany} visible={newVisible} />

      <EditTransactionSlide hide={hideEdit} projectId={id} showNewCompany={showNewCompany} transaction={editData} visible={editVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <TransactionSettingsSlide hide={hideSettings} project={project} projectId={id} visible={settingsVisible} />

      <div className="mb-8 flex justify-between">
        <div>
          <h2 className="text-xl font-medium text-gray-800">Liegenschaftsankäufe</h2>

          <div className="mt-8 flex items-center">
            <h3 className="text-lg font-medium">Einstellungen</h3>

            {showEditActions && !hasBaurecht
              ? (
                <button className="ml-2 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={showSettings} type="button">
                  <EditIcon className="size-4" />
                </button>
              )
              : null}
          </div>

          <div className="mt-2">
            <p>Grundkostenanteil anzeigen: {project?.show_gka && !hasBaurecht ? <span className="rounded-sm bg-emerald-300 px-2 py-1 text-emerald-800">Ja</span> : <span className="rounded-sm bg-red-300 px-2 py-1 text-red-800">Nein</span>}{hasBaurecht ? " (Baurecht)" : null}</p>

            <p className="mt-2">Information zur Berechnung des GKA:{project?.gka_notes ? "" : " -"}</p>

            <p className="whitespace-pre-line leading-tight text-gray-700">{project?.gka_notes}</p>

            {project?.gka_area ? <p className="mt-2 rounded-sm bg-gray-700 px-2 py-1 text-white">{formatArea(project.gka_area)} Fläche (manuelle Berechnung wird angewendet)</p> : null}
          </div>

        </div>

        {showEditActions
          ? (
            <div>
              <SubmitButton onClick={showNew}>
                <PlusIcon className="size-5" />
              </SubmitButton>
            </div>
          )
          : null}
      </div>

      {project?.transactions
        .sort((transactionA, transactionB) => {
          if (transactionA.sorting === null && transactionB.sorting === null) {
            return 0;
          }
          else if (transactionA.sorting === null) {
            return 1;
          }
          else if (transactionB.sorting === null) {
            return -1;
          }

          return transactionA.sorting - transactionB.sorting;
        })
        .map((transaction) => {
          const sellerPrivate = transaction.seller_private === true || false;
          const buyerPrivate = transaction.buyer_private === true || false;
          const isBaurecht = transaction.transaction_category?.code === "baurecht";

          return (
            <div className="flex flex-col gap-2" key={`transaction_${transaction.id}`}>
              <div key={`transaction_sorting_${transaction.id}`}>
                <span className="rounded-md bg-gray-300 px-2 text-sm font-bold text-gray-900">
                  Nr. {`${(transaction.sorting) ? formatDecimalExactPrecision(transaction.sorting) : "-"}`}
                </span>
              </div>

              <div className="mb-8 grid grid-cols-5 gap-x-2 gap-y-6 bg-gray-100 p-4" key={`transaction_content_${transaction.id}`}>
                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Aktiv (für GKA)</p>

                  <p className="mt-1 font-medium text-emerald-900">
                    <span
                      className={cn("px-2 py-1 rounded-sm", {
                        "bg-emerald-300 text-emerald-800": transaction.usable,
                        "bg-red-300 text-red-800": !transaction.usable
                      })}
                    >
                      {transaction.usable ? "ja" : "nein"}
                    </span>
                  </p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Historisch</p>

                  <p className="mt-1 font-medium text-emerald-900">
                    <span
                      className={cn("px-2 py-1 rounded-sm", {
                        "bg-emerald-300 text-emerald-800": transaction.historic,
                        "bg-red-300 text-red-800": !transaction.historic
                      })}
                    >
                      {transaction.historic ? "ja" : "nein"}
                    </span>
                  </p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">KV-Detail ID</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.kv_id ? transaction.kv_id : "-"}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">KV-Detail Link</p>

                  <p className="mt-1 font-medium text-gray-900">
                    {transaction.kv_url
                      ? (
                        <a className="underline" href={transaction.kv_url} rel="noreferrer noopener" target="_blank">
                          {transaction.kv_url}
                        </a>
                      )
                      : null}

                    {!transaction.kv_url && "-"}
                  </p>
                </div>

                <div className="col-span-1">
                  {showEditActions
                    ? (
                      <div className="col-span-1 flex items-center justify-end">
                        <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showEdit(transaction)} type="button">
                          <EditIcon className="size-6" />
                        </button>

                        <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showDelete(transaction)} type="button">
                          <DeleteIcon className="size-6" />
                        </button>
                      </div>
                    )
                    : null}
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Vertragsdatum</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.transaction_date ? formatDate({ date: transaction.transaction_date }) : "-"}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Objektkategorie</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.transaction_category ? transaction.transaction_category.name : "-"}</p>
                </div>

                <div className="col-span-3" />

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">TZ</p>

                  <p className="mt-1 font-medium text-gray-900">
                    {transaction.tz_number ? transaction.tz_number : "-"}

                    {transaction.tz_year ? `/${dateFormatter(transaction.tz_year, "year")}` : "-"}
                  </p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">GST-Nr.</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.gst_numbers.join(", ")}</p>
                </div>

                {isBaurecht
                  ? (
                    <>
                      <div className="col-span-1">
                        <p className="text-lg font-medium text-gray-500">BREZ</p>

                        <p className="mt-1 font-medium text-gray-900">{transaction.brez ? transaction.brez : "-"}</p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-lg font-medium text-gray-500">EZ Stammliegenschaft</p>

                        <p className="mt-1 font-medium text-gray-900">{transaction.br_stamm_ez ? transaction.br_stamm_ez : "-"}</p>
                      </div>
                    </>
                  )
                  : null}

                {!isBaurecht && (
                  <div className="col-span-1">
                    <p className="text-lg font-medium text-gray-500">EZ</p>

                    <p className="mt-1 font-medium text-gray-900">{transaction.ez_numbers ? transaction.ez_numbers.join(", ") : "-"}</p>
                  </div>
                )}

                <div className="col-span-1 col-start-1">
                  <p className="text-lg font-medium text-gray-500">KG</p>

                  <p className="mt-1 font-medium text-gray-900">
                    {transaction.katastralgemeinde ? `${transaction.katastralgemeinde.code}` : "-"}
                  </p>

                  <p className=" truncate font-medium text-gray-900">
                    {transaction.katastralgemeinde ? `${transaction.katastralgemeinde.name}` : ""}
                  </p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">BG</p>

                  <p className="mt-1 font-medium text-gray-900">
                    {transaction.katastralgemeinde ? `${transaction.katastralgemeinde.bezirksgericht.code}` : "-"}
                  </p>

                  <p className="truncate font-medium text-gray-900">
                    {transaction.katastralgemeinde ? `${transaction.katastralgemeinde.bezirksgericht.name}` : ""}
                  </p>
                </div>

                <div className="col-span-3" />

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">GST-Fläche</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.property_area ? formatArea(transaction.property_area) : "-"}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Baufläche (Gebäude)</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.build_area ? formatArea(transaction.build_area) : "-"}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Baufläche (begrünt)</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.green_area ? formatArea(transaction.green_area) : "-"}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-lg font-medium text-gray-500">Nutzfläche</p>

                  <p className="mt-1 font-medium text-gray-900">{transaction.usable_area ? formatArea(transaction.usable_area) : "-"}</p>
                </div>

                <div className="col-span-1" />

                {isBaurecht
                  ? (
                    <>
                      <div className="col-span-1">
                        <p className="text-lg font-medium text-gray-500">Baurechtsdauer</p>

                        <p className="mt-1 text-gray-900">{transaction.baurechtsdauer ? transaction.baurechtsdauer : "-"}</p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-lg font-medium text-gray-500">Baurecht bis</p>

                        <p className="mt-1 text-gray-900">{transaction.baurechtsdauer_date ? dateFormatter(transaction.baurechtsdauer_date, "day") : "-"}</p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-lg font-medium text-gray-500">Bauzins / Monat netto</p>

                        <p className="mt-1 text-gray-900">{transaction.bauzins_net ? formatEuro(transaction.bauzins_net) : "-"}</p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-lg font-medium text-gray-500">Bauzins / Monat brutto</p>

                        <p className="mt-1 text-gray-900">{transaction.bauzins_gross ? formatEuro(transaction.bauzins_gross) : "-"}</p>
                      </div>
                    </>
                  )
                  : null}

                {!isBaurecht && (
                  <>
                    <div className="col-span-1">
                      <p className="text-lg font-medium text-gray-500">Kaufpreis netto</p>

                      <p className="mt-1 truncate font-medium text-gray-900">{transaction.sale_price_net ? formatEuro(transaction.sale_price_net) : "-"}</p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-lg font-medium text-gray-500">Kaufpreis brutto</p>

                      <p className="mt-1 truncate font-medium text-gray-900">{transaction.sale_price_gross ? formatEuro(transaction.sale_price_gross) : "-"}</p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-lg font-medium text-gray-500">Anteile</p>

                      <p className="mt-1 font-medium text-gray-900">{transaction.shares ? transaction.shares : "-"}</p>
                    </div>

                    <div className="col-span-2" />
                  </>
                )}

                <div className="col-span-2">
                  <p className="text-lg font-medium text-gray-500">{isBaurecht ? "Baurechtsgeber" : "Verkäufer"}</p>

                  {sellerPrivate ? <p className="mt-1 font-medium text-gray-900">privat</p> : null}

                  {!sellerPrivate && (
                    <>
                      <p className="mt-1 font-medium text-gray-900">{transaction.seller?.name}</p>

                      <p className="truncate font-medium text-gray-900">{transaction.seller?.reg_number}</p>
                    </>
                  )}
                </div>

                <div className="col-span-2">
                  <p className="text-lg font-medium text-gray-500">{isBaurecht ? "Bauberechtigter" : "Käufer"}</p>

                  {buyerPrivate ? <p className="mt-1 font-medium text-gray-900">privat</p> : null}

                  {!buyerPrivate && (
                    <>
                      <p className="mt-1 font-medium text-gray-900">{transaction.buyer?.name}</p>

                      <p className="truncate font-medium text-gray-900">{transaction.buyer?.reg_number}</p>
                    </>
                  )}

                </div>

                <div className="col-span-4">
                  <p className="text-lg font-medium text-gray-500">Bemerkungen</p>

                  <p className="mt-1 whitespace-pre-line font-medium leading-tight text-gray-900">{transaction.notes ? transaction.notes : "-"}</p>
                </div>
              </div>
            </div>

          );
        })}

    </div>
  );
};

export default ProjectTransactions;
