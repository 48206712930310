import axios from "axios";
import cn from "classnames";
import { useEffect, useState } from "react";

import API from "~/src/modules/api.js";
import { formatBytes } from "~/src/modules/formatters.js";

import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import SVGIcon from "~/src/ui/icons/svg-icon/index.jsx";

import ProjectDocumentSuggestions from "./project-document-suggestions.jsx";
import UnitDocumentSuggestions from "./unit-document-suggestions.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.file - The root object
 * @param props0.form - The root object
 * @param props0.index - The root object
 * @param props0.onUpload - The root object
 * @param props0.projectDocuments - The root object
 * @param props0.unitDocuments - The root object
 * @example
 */
const SingleFileUploadField = ({
  file, form, index, onUpload, projectDocuments = false, unitDocuments = false
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {


    /**
     *
     * @example
     */
    async function upload() {
      const signedUrl = await getSignedUrl(file);

      await uploadFile(signedUrl, file, setProgress);
      onUpload(file);
    }

    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param value
   * @example
   */
  function fillPublicName(value) {
    const { values } = form;

    const properties = {
      ...values.files
    };

    properties[index].publicName = value;

    form.updateProperties(properties);
  }

  return (
    <div
      className={cn("grid grid-cols-4 gap-x-8 p-4", {
        "bg-gray-50": index % 2 === 0,
        "border-t border-gray-300": index === 0
      })}
    >

      <div className="grid-cols-1">
        <div className="h-20 w-24" >
          <div className="flex min-w-0 overflow-hidden">

            {file.type === "application/pdf"
              ? (
                <div className="flex size-full items-center justify-start">
                  <SVGIcon />
                </div>
              )
              : <img alt="" className="block h-full w-auto" src={file.preview} />}

          </div>
        </div>

        <div className="text-sm text-gray-700">
          <p>{file.name}</p>

          <p>{formatBytes(file.blob ? file.blob.size : file.size)}</p>

          <div className="mt-2 bg-gray-200 shadow-sm">
            <div className="bg-indigo-500 py-1 text-center text-xs leading-none text-white" style={{ width: `${progress}%` }}>{progress} %</div>
          </div>
        </div>
      </div>

      <div className="col-span-2 col-start-3 grid items-center text-gray-700">
        <div>
          {file?.uploaded === true && (
            <div className="">

              <div>
                <InlineCheckbox
                  error={form.values.files?.[index]?.showPublic}
                  id={`files.${index}.showPublic`}
                  isSubmitting={form.isSubmitting}
                  label="Dokument öffentlich anzeigen"
                  name={`files.${index}.showPublic`}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.values.files?.[index]?.showPublic}
                  value={form.values.files?.[index]?.showPublic || false}
                />
              </div>

              <div className="mt-6">
                <InlineInput
                  error={form.errors.files?.[index]?.publicName}
                  id={`files.${index}.publicName`}
                  isSubmitting={form.isSubmitting}
                  name={`files.${index}.publicName`}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Öffentlicher Dateiname"
                  touched={form.touched.files?.[index]?.publicName}
                  value={form.values.files?.[index]?.publicName || ""}
                />
              </div>

              <div className="mt-4">
                {projectDocuments ? <ProjectDocumentSuggestions handleClick={fillPublicName} /> : null}

                {unitDocuments ? <UnitDocumentSuggestions handleClick={fillPublicName} /> : null}
              </div>

            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default SingleFileUploadField;

/**
 *
 * @param props0 - The root object
 * @param props0.blob - The root object
 * @param props0.fileName - The root object
 * @param props0.type - The root object
 * @example
 */
function getSignedUrl({
  blob, fileName, type
}) {
  const file = {
    name: fileName,
    type: blob ? blob.type : type
  };

  return new Promise((resolve, reject) => {
    API.post("/files/sign-upload", { file })
      .then((res) => {
        resolve(res.data.payload.url);
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });
}

/**
 *
 * @param url
 * @param file
 * @param onProgress
 * @example
 */
function uploadFile(url, file, onProgress) {
  const instance = axios.create();

  // TODO[2025-01-01]: Find a native replacement for upload progress (or remove it (sebi allowed removal of progress indication as long as we display when it's finished))

  return new Promise((resolve, reject) => {
    instance.put(url, file.blob ? file.blob : file, {
      headers: {
        "Content-Type": file.blob ? file.blob.type : file.type
      },
      onUploadProgress: (event) => {
        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100;

          onProgress(Math.round(percentage));
        }
      }
    })
      .then((res) => {
        resolve();
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
}
