/**
 * @import { CertificateIssuer } from "./certificate-issuer.doc.js";
 * @import { IssuerCertificate } from "./issuer-certificate.doc.js";
 */

/**
 * @typedef {object} ProjectCertificate
 * @property {number} id
 * @property {number|null} assigned_points
 * @property {CertificateIssuer} issuer
 * @property {CertificateIssuer["id"]} issuer_id
 * @property {IssuerCertificate|null} issuer_certificate
 * @property {IssuerCertificate["id"]|null} issuer_certificate_id
 * @property {string} notes
 */
