import {
  Disclosure, DisclosureButton, DisclosurePanel
} from "@headlessui/react";
import cn from "classnames";
import dayjs from "dayjs";
import {
  CircleCheckIcon, CircleXIcon, FileTextIcon, MapPinIcon
} from "lucide-react";
import { Fragment } from "react";

import {
  formatArea, formatDate, formatEuro
} from "~/src/modules/formatters.js";
import groupBy from "~/src/modules/group-by.js";

import ProjectItemSkeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @param root0 - The root object
 * @param root0.contracts - The root object
 * @param root0.project - The root object
 * @param root0.isLoading - The root object
 * @example
 */
const ContractList = ({
  contracts, isLoading, project
}) => {
  const groupedContracts = contracts && groupBy(contracts, "contract_type");

  const contractTypes = [
    "Wohnung",
    "Dachgeschosswohnung",
    "Einfamilienhaus",
    "Mehrfamilienhaus",
    "Doppelhaushälfte",
    "Reihenhaus",
    "Lager",
    "Büro",
    "Kellerabteil",
    "Geschäftslokal",
    "Kleingarten",
    null || undefined,
    "PKW-Abstellplatz",
    "Gebäude",
    "Gebäude am See",
    "Grundstück",
    "Seegrundstück",
    "Zinshaus",
    "Zinshausanteil",
    "Hausanteil",
    "Dachboden",
    "Hotel/Pension",
    "Bürogebäude",
    "Sportplatz",
    "Landwirtschaft",
    "Straßenanlage",
    "Wald",
    "Alpe",
    "Sonderfall"
  ];

  const sortedGroupedContacts = groupedContracts &&
    contractTypes
      .filter((contractType) => contractType in groupedContracts)

      .map((contractType) => groupedContracts[contractType]);

  if (isLoading) {
    return (
      <div>
        <div className="mt-8 flex justify-between">
          <ProjectItemSkeleton />

          <ProjectItemSkeleton />

          <ProjectItemSkeleton />

          <ProjectItemSkeleton />
        </div>

        <div className="mt-8 flex justify-between">
          <ProjectItemSkeleton />

          <ProjectItemSkeleton />

          <ProjectItemSkeleton />

          <ProjectItemSkeleton />
        </div>
      </div>

    );
  }

  if (!contracts || contracts.length === 0) {
    return <span className="mt-4 flex text-lg text-gray-500">Keine Kaufverträge gefunden...</span>;
  }

  return (
    <div className="flex flex-col gap-8">
      {
        sortedGroupedContacts
          ?.map((contracts, groupIndex) => (
            <div className="flex flex-col gap-1" key={groupIndex}>
              <h3 className="text-lg">{contracts[0].contract_type ?? "Ohne Typ"}</h3>

              <div className="rounded-xs grid grid-cols-2 overflow-hidden border border-gray-200 md:grid-cols-1">
                <div className="grid-cols-28 hidden border-b-2 border-gray-200 bg-gray-50 md:grid">

                  <div className="col-span-2 whitespace-nowrap px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Änderungen</p>
                  </div>

                  <div className="col-span-2 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Verbüchert</p>
                  </div>

                  <div className="col-span-2 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>KV-Datum</p>
                  </div>

                  <div className="col-span-3 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>KV-ID</p>
                  </div>

                  <div className="col-span-2 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Typ</p>
                  </div>

                  <div className="col-span-4 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Adresse</p>
                  </div>

                  <div className="col-span-2 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Top</p>
                  </div>

                  <div className="col-span-2 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Nutzfläche</p>
                  </div>

                  <div className="col-span-3 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Preis</p>
                  </div>

                  <div className="col-span-5 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <p>Verkäufer</p>
                  </div>

                  <div className="col-span-1 whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" />

                </div>

                <div className="col-span-2 grid grid-cols-1 divide-y divide-gray-200 md:col-span-1">
                  {
                    contracts
                      ?.map((contract, index) => {
                        // typeId 1-buyer | 0-seller
                        // categoryId 2-firma | 3-privat
                        const buyers = contract.persons
                          .filter((person) => person.typeId === 1)
                          .map((person) => {
                            const personSettings = {
                              address: `${person.street || ""} ${person.streetNumber || ""}${(person.stairs) ? `/${person.stairs}` : ""}${(person.top) ? `/${person.top}` : ""}`,
                              birthdate_fbnr: `${formatDate({
                                date: person.birthdate,
                                withTime: false
                              }) || person?.companyNumber || ""}`,
                              location: `${person.zip || ""} ${person.city || ""} - ${person.country || ""}`,
                              name: `${person.title || ""} ${person.firstname || ""} ${person.lastname || ""} ${person.titleSuffix || ""}`
                            };

                            return Object.fromEntries(Object.entries(personSettings).map(([attribute, value]) => [attribute, (value === "") ? "-" : value]));
                          });

                        const sellers = contract.persons
                          .filter((person) => person.typeId === 0)
                          .map((person) => {
                            const personSettings = {
                              address: `${person.street || ""} ${person.streetNumber || ""}${(person.stairs) ? `/${person.stairs}` : ""}${(person.top) ? `/${person.top}` : ""}`,
                              birthdate_fbnr: `${formatDate({
                                date: person.birthdate,
                                withTime: false
                              }) || person?.companyNumber || ""}`,
                              location: `${person.zip || ""} ${person.city || ""} - ${person.country || ""}`,
                              name: `${person.title || ""} ${person.firstname || ""} ${person.lastname || ""} ${person.titleSuffix || ""}`
                            };

                            return Object.fromEntries(Object.entries(personSettings).map(([attribute, value]) => [attribute, (value === "") ? "-" : value]));
                          });

                        return (
                          <div
                            key={`contract_${contract.id}_${index}`}
                            className={cn("col-span-1", {
                              "bg-gray-50": index % 2 !== 0
                            })}
                          >

                            <Disclosure>

                              <DisclosureButton className="grid-cols-28 items-center md:grid">

                                <div className="col-span-2 flex items-center whitespace-nowrap p-2 text-sm font-semibold text-gray-900">
                                  {formatDate({
                                    date: contract.change_date,
                                    withTime: false
                                  })}
                                </div>

                                <div className="col-span-2 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  {formatDate({
                                    date: contract.verb_date,
                                    withTime: false
                                  })}
                                </div>

                                <div className="col-span-2 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  {formatDate({
                                    date: contract.kv_date,
                                    withTime: false
                                  })}
                                </div>

                                <div className="col-span-3 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <div className="flex items-center">
                                    <a
                                      className="underline hover:no-underline"
                                      href={`https://kvea.immounited.com/?DocumentId=${contract.kv_id}`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      <FileTextIcon className="size-5 text-gray-600 hover:text-gray-700" />
                                    </a>

                                    <a
                                      className="underline hover:no-underline"
                                      href={`https://immobase.immounited.com/IMMOmapping/Default.aspx?mapmode=1&contractOfPurchaseId=${contract.kv_id}`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      <MapPinIcon className="size-5 text-gray-600 hover:text-gray-700" />
                                    </a>
                                  </div>

                                  <span className="ml-1">{contract.kv_id}</span>
                                </div>

                                <div className="relative col-span-2 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <span className="truncate" title={contract.contract_type}>{contract.contract_type}</span>
                                </div>

                                <div className="relative col-span-4 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <span className="truncate" title={`${contract.street} ${contract.house_number}`}>{contract.street} {contract.house_number}</span>
                                </div>

                                <div className="relative col-span-2 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <span className="truncate">{contract.top}</span>
                                </div>

                                <div className="col-span-2 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <span className="truncate">{formatArea(contract.nutzflaeche)}</span>
                                </div>

                                <div className="col-span-3 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <span>{formatEuro(contract.price_net)}</span>
                                </div>

                                <div className="relative col-span-5 flex items-center whitespace-nowrap p-2 text-sm text-gray-900">
                                  <span className="truncate" title={contract.seller}>{contract.seller}</span>
                                </div>

                                <div className="col-span-1 flex items-center whitespace-nowrap p-2 text-sm text-gray-900 ">
                                  {project && !contract.used
                                    ? (
                                      <div className="flex w-8 items-center justify-center rounded-sm bg-pink-300 py-0.5 text-pink-700">
                                        <CircleXIcon className="size-5" />
                                      </div>
                                    )
                                    : null}

                                  {
                                    contract.used &&
                                    (
                                      !project?.last_unit_sale_research_date ||
                                      dayjs(contract.change_date).isBefore(project?.last_unit_sale_research_date)
                                    )
                                      ? (
                                        <div className="flex w-8 items-center justify-center rounded-sm bg-green-300 py-0.5 text-green-700">
                                          <CircleCheckIcon className="size-5" />
                                        </div>
                                      )
                                      : null
                                  }

                                  {
                                    contract.used &&
                                    project?.last_unit_sale_research_date &&
                                    !dayjs(contract.change_date).isBefore(project?.last_unit_sale_research_date)
                                      ? (
                                        <div className="flex w-8 items-center justify-center rounded-sm bg-amber-300 py-0.5 text-amber-700">
                                          <CircleCheckIcon className="size-5" />
                                        </div>
                                      )
                                      : null
                                  }
                                </div>
                              </DisclosureButton>

                              <DisclosurePanel className="px-2 pb-8 pt-2">

                                <h3 className="font-bold">KV-ID: <span className="text-sm font-light">{contract.kv_id}</span></h3>

                                <h3 className="font-bold">
                                  KV-Datum:
                                  <span className="text-sm font-light">
                                    {formatDate({
                                      date: contract.kv_date,
                                      withTime: false
                                    })}
                                  </span>
                                </h3>

                                <h3 className="font-bold">KG: <span className="text-sm font-light">{contract.kgnr}</span></h3>

                                <h3 className="font-bold">EZ: <span className="text-sm font-light">{contract.ez}</span></h3>

                                <p className="mt-2 font-bold">{contract.contract_type}</p>

                                <p className="font-bold leading-none">{contract.street} {contract.house_number} {contract.staircase && `Stiege ${contract.staircase}`} {contract.top && `Top ${contract.top}`}</p>

                                <p className="text-sm">{contract.zipcode} {contract.city}</p>

                                <div className="mt-4 grid grid-cols-4">
                                  <div className="col-span-4">
                                    <div className="mt-2 grid grid-cols-4">
                                      <p className="h-5 text-sm font-bold">Verkäufer:</p>

                                      <p className="h-5 text-sm font-bold">Geb./FBNr.:</p>

                                      <p className="h-5 text-sm font-bold">Adresse:</p>

                                      <p className="h-5 text-sm font-bold">Ort:</p>

                                      {
                                        sellers
                                          ? sellers.map((seller, index) => (
                                            <Fragment key={`seller'_${index}`}>
                                              <p className="min-h-5 text-sm">{seller.name}</p>

                                              <p className="min-h-5 text-sm">{seller.birthdate_fbnr}</p>

                                              <p className="min-h-5 text-sm">{seller.address}</p>

                                              <p className="min-h-5 text-sm">{seller.location}</p>
                                            </Fragment>
                                          ))
                                          : null
                                      }
                                    </div>

                                    <div className="mt-2 grid grid-cols-4">
                                      <p className="h-5 text-sm font-bold">Käufer:</p>

                                      <p className="h-5 text-sm font-bold">Geb./FBNr.:</p>

                                      <p className="h-5 text-sm font-bold">Adresse:</p>

                                      <p className="h-5 text-sm font-bold">Ort:</p>

                                      {
                                        buyers
                                          ? buyers.map((buyer, index) => (
                                            <Fragment key={`buyer_${index}`}>
                                              <p className="min-h-5 text-sm">{buyer.name}</p>

                                              <p className="min-h-5 text-sm">{buyer.birthdate_fbnr}</p>

                                              <p className="min-h-5 text-sm">{buyer.address}</p>

                                              <p className="min-h-5 text-sm">{buyer.location}</p>
                                            </Fragment>
                                          ))
                                          : null
                                      }
                                    </div>
                                  </div>

                                  <div>
                                    <p className="mt-4 h-5 bg-gray-50 text-sm font-bold">TZ:</p>

                                    <p className="h-5 bg-white text-sm font-bold">GST-Nr:</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold">GST-Fläche (m²):</p>

                                    <p className="h-5 bg-white text-sm font-bold">B-Blatt-Anteile:</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold" />

                                    <p className="h-5 bg-white text-sm font-bold">Kaufpreis (€ inkl. USt.):</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold">Kaufpreis (€ exkl. USt.):</p>

                                    <p className="h-5 bg-white text-sm font-bold" />

                                    <p className="h-5 bg-gray-50 text-sm font-bold">Bemerkungen:</p>
                                  </div>

                                  <div>
                                    <p className="mt-4 h-5 bg-gray-50 text-sm">{contract.tz}/{contract.tz_year}</p>

                                    <p className="h-5 bg-white text-sm">{contract.gst_numbers}</p>

                                    <p className="h-5 bg-gray-50 text-sm">{formatArea(contract.flaeche)}</p>

                                    <p className="h-5 bg-white text-sm">{contract.shares1}/{contract.shares2}</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold" />

                                    <p className="h-5 bg-white text-sm">{contract.price_total ? formatEuro(contract.price_total) : "-"}</p>

                                    <p className="h-5 bg-gray-50 text-sm">{contract.price_net ? formatEuro(contract.price_net) : contract.price_net}</p>

                                    <p className="h-5 bg-white text-sm font-bold" />

                                    <p className="bg-gray-50 text-sm">{contract.comment ? contract.comment : "-"}</p>
                                  </div>

                                  <div>
                                    <p className="mt-4 h-5 bg-gray-50 text-sm font-bold">Nutzfläche (m²):</p>

                                    <p className="h-5 bg-white text-sm font-bold">Loggia:</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold">Balkon:</p>

                                    <p className="h-5 bg-white text-sm font-bold">Terrasse:</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold">Garten:</p>

                                    <p className="h-5 bg-white text-sm font-bold">Stockwerk:</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold">Zimmer:</p>

                                    <p className="h-5 bg-white text-sm font-bold">Keller/Lager:</p>

                                    <p className="h-5 bg-gray-50 text-sm font-bold">PKW Abstellplatz:</p>
                                  </div>

                                  <div>
                                    <p className="mt-4 h-5 bg-gray-50 text-sm">{contract.nutzflaeche ? formatArea(contract.nutzflaeche) : "-"}</p>

                                    <p className="h-5 bg-white text-sm">{contract.has_loggia ? `${formatArea(contract.loggia)} ${(contract.loggia_count) ? `(${contract.loggia_count})` : ""}` : "-"}</p>

                                    <p className="h-5 bg-gray-50 text-sm">{contract.has_balkon ? `${formatArea(contract.balkon)} ${(contract.balkon_count) ? `(${contract.balkon_count})` : ""}` : "-"}</p>

                                    <p className="h-5 bg-white text-sm">{contract.has_terrasse ? `${formatArea(contract.terrasse)} ${(contract.terrasse_count) ? `(${contract.terrasse_count})` : ""}` : "-"}</p>

                                    <p className="h-5 bg-gray-50 text-sm">{contract.has_garten ? formatArea(contract.garten) : "-"}</p>

                                    <p className="h-5 bg-white text-sm">{contract.has_floor ? contract.floor : "-"}</p>

                                    <p className="h-5 bg-gray-50 text-sm">{contract.has_rooms ? contract.rooms : "-"}</p>

                                    <p className="h-5 bg-white text-sm">{contract.has_keller ? formatArea(contract.keller) : "-"}</p>

                                    <p className="h-5 bg-gray-50 text-sm">{contract.has_pkw ? "Ja" : "-"}</p>
                                  </div>
                                </div>

                              </DisclosurePanel>
                            </Disclosure>

                          </div>
                        );
                      })
                  }
                </div>
              </div>
            </div>
          ))
      }
    </div>
  );
};

export default ContractList;
