import {
  Link, useParams, useRouteMatch
} from "react-router-dom";

import AutomatedProjectUnits from "~/src/features/automated-project-units/index.jsx";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";
import Page from "~/src/ui/page/index.jsx";

/**
 *
 * @example
 */
const AutomatedProjectUnitsListPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useAutomatedProject(id);
  const { url } = useRouteMatch();

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <Page className="bg-transparent!" title="Objekte - Allgemein">
      <AutomatedProjectUnits />
    </Page>
  );
};

export default AutomatedProjectUnitsListPage;
