import initialValues from "../_common/initial-values.js";

/**
 * Generates form values for editing an existing project certificate
 *
 * @param {object} projectCertificate - The project certificate to edit
 * @returns {object} Form values initialized with project certificate data
 * @example
 */
const generateEditValues = (projectCertificate) => ({
  ...initialValues,
  ...projectCertificate
});

export default generateEditValues;
