import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from "@headlessui/react";

import { CertificateIssuerForm } from "./certificate-issuer-modal/_exports.js";

const titles = new Map([
  ["create", "Neuen Aussteller hinzufügen"],
  ["edit", "Aussteller bearbeiten"],
  ["view", "Ausstellerdetails"]
]);

const CertificateIssuerModal = ({
  close,
  isOpen,
  issuer,
  mode,
  mutate
}) => (
  <Dialog className="relative z-40" onClose={close} open={isOpen}>
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-black/30 duration-300 ease-out data-[closed]:opacity-0"
    />

    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
      <DialogPanel
        transition
        className="flex max-h-full w-8/12 max-w-lg  overflow-hidden rounded-lg bg-white duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <div
          className="flex max-h-full w-full flex-col gap-4 overflow-auto  p-6"
        >
          <DialogTitle className="text-lg font-medium">{titles.get(mode)}</DialogTitle>

          <CertificateIssuerForm
            {...{
              close,
              issuer,
              mode,
              mutate
            }}
          />
        </div>

      </DialogPanel>
    </div>
  </Dialog>
);

export default CertificateIssuerModal;
