import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";

import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";
import RoleSelect from "~/src/ui/selects/role-select/index.jsx";

import { handleUpdate } from "./handlers.js";
import { generateEditValues, schema } from "./schema.js";

const EditUserModal = ({
  hide, mutateUsers = () => null, user, visible
}) => {
  const { showError } = useNotification();

  const initialValues = generateEditValues(user);

  const handleHide = (innerForm) => {
    hide();
    innerForm.resetForm();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: user.id,
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate: mutateUsers,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <Modal hide={() => handleHide(form)} show={visible}>
      <div className="inline-block w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle" role="dialog">
        <h3 className="text-lg font-medium">User bearbeiten</h3>

        <form autoComplete="off" onSubmit={form.handleSubmit}>
          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="role">
              Rolle
            </label>

            <div className="mt-1">
              <RoleSelect
                defaultValue={form.values.role}
                error={form.errors.role}
                isSubmitting={form.isSubmitting}
                name="role"
                onChange={form.handleChange}
                touched={form.touched.role}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Email
            </label>

            <div className="mt-1">
              <InlineInput
                disabled
                error={form.errors.email}
                id="email"
                isSubmitting={form.isSubmitting}
                name="email"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                placeholder="Email"
                touched={form.touched.email}
                type="email"
                value={form.values.email}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="username">
              Name
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.username}
                id="username"
                isSubmitting={form.isSubmitting}
                name="username"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                placeholder="Name"
                touched={form.touched.username}
                value={form.values.username}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
              Neues Passwort
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.password}
                id="password"
                isSubmitting={form.isSubmitting}
                name="password"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                placeholder="Passwort"
                touched={form.touched.password}
                value={form.values.password}
              />
            </div>
          </div>

          <div className="mt-5 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <FormSubmitButton className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-700 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-gray-800 focus:outline-hidden focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 sm:col-start-2 sm:text-sm" isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>

            <button className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-xs hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" onClick={() => handleHide(form)} type="button">
              abbrechen
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditUserModal;
