import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import DeleteButton from "~/src/ui/buttons/delete-edit-project-button/index.jsx";
import EditButton from "~/src/ui/buttons/edit-project-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import ProjectDescriptionForm from "./form.jsx";
import { handleUpdate } from "./handlers.js";

/**
 *
 * @example
 */
const ProjectDescription = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const {
    editPossible, editing, setEditing
  } = useEdit({
    project,
    user
  });

  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete: (parameters) => {
            handleUpdate({
              ...parameters,
              data: { description: null }
            });
          },
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Kurzbeschreibung" />

        <DescriptionContainer>
          {editing
            ? <ProjectDescriptionForm projectId={id} setEditing={setEditing} />
            : <span className="whitespace-pre-line">{project?.description}</span>}
        </DescriptionContainer>

        <div className="flex flex-col gap-4">
          <EditButton
            editPossible={editPossible}
            editing={editing}
            onClick={() => setEditing((previous) => !previous)}
          />

          {
            (project && project.description && !editing)
              ? (
                <DeleteButton
                  editPossible={editPossible}
                  onClick={() => showDelete(project)}
                />
              )
              : null
          }
        </div>

      </div>

    </TabListContainer>
  );
};

export default ProjectDescription;
