/* eslint-disable max-lines-per-function */
import cn from "classnames";
import { useParams } from "react-router-dom";

import { pluralize } from "~/src/modules/formatters.new.js";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import {
  ProjectCertificatesPortals,
  replaceNullWithUndefinedProperties
} from "./project-certificates/_exports.js";

const ProjectCertificates = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const {
    isError,
    isLoading,
    mutate,
    project
  } = useProject(id);

  const { editPossible: showEditActions } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading || isError || !project) {
    return null;
  }

  const {
    certificates: projectCertificates
  } = project;

  return (
    <TabListContainer>

      <ProjectCertificatesPortals
        {...{
          deleteData,
          deleteVisible,
          hideDelete,
          hideSlide,
          mutate,
          slideData,
          slideVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Gebäudezertifikate" />

        <DescriptionContainer>

          {
            projectCertificates.map((projectCertificate, index) => {
              const {
                id: projectCertificateId,
                assigned_points: projectCertificateAssignedPoints,
                issuer: {
                  max_points: issuerMaxPoints,
                  has_certificates: issuerHasCertificates,
                  has_points: issuerHasPoints,
                  name: issuerName
                },
                issuer_certificate: {
                  name: certificateName
                } = {},
                notes: projectCertificateNotes
              } = replaceNullWithUndefinedProperties(projectCertificate);

              return (

                <div
                  key={projectCertificateId}
                  className={cn("grid grid-cols-4 gap-2 p-2", {
                    "bg-gray-100": index % 2 === 0
                  })}
                >

                  <div className="col-span-1">
                    <p className="text-sm font-medium text-gray-700">Aussteller</p>

                    <p>{issuerName}</p>
                  </div>

                  {
                    issuerHasCertificates && (
                      <div className="col-span-1">
                        <p className="text-sm font-medium text-gray-700">Zertifikat</p>

                        <p>{certificateName ?? "-"}</p>
                      </div>
                    )
                  }

                  {
                    issuerHasPoints && (
                      <div className="col-span-1">
                        <p className="text-sm font-medium text-gray-700">Punktesystem</p>

                        <p>
                          {
                            typeof projectCertificateAssignedPoints === "number"
                              ? (
                                <>
                                  <span>{projectCertificateAssignedPoints}</span>

                                  <span> von </span>

                                  <span>{issuerMaxPoints}</span>

                                  <span> {pluralize("Punkt", "Punkten", issuerMaxPoints)}</span>
                                </>
                              )
                              : (
                                <span>-</span>
                              )
                          }

                        </p>
                      </div>
                    )
                  }

                  {
                    showEditActions
                      ? (
                        <div className="col-span-1 col-end-5 flex items-center justify-end">
                          <button className="focus:outline-hidden inline-flex items-center text-gray-500 hover:text-gray-400" onClick={() => showSlide(projectCertificate)} type="button">
                            <EditIcon className="size-6" />
                          </button>

                          <button className="focus:outline-hidden ml-6 inline-flex items-center text-gray-500 hover:text-gray-400" onClick={() => showDelete(projectCertificate)} type="button">
                            <DeleteIcon className="size-6" />
                          </button>
                        </div>
                      )
                      : (<div className="col-span-1" />)
                  }

                  <div className="col-span-2">
                    <p className="text-sm font-medium text-gray-700">Sonstige Bemerkungen</p>

                    <p>{projectCertificateNotes || "-"}</p>
                  </div>

                </div>
              );
            })
          }
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide()}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectCertificates;
