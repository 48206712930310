import {
  Label, Switch, Field
} from "@headlessui/react";
import cn from "classnames";
import {
  CircleCheckIcon, CircleXIcon, RefreshCcwIcon
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import API from "~/src/modules/api.js";

import ProjectContractList from "~/src/features/contract-list/index.jsx";
import DeleteContractRegionModal from "~/src/features/modals/contract-regions/delete-contract-region-modal/index.jsx";
import NewContractRegionModal from "~/src/features/modals/contract-regions/new-contract-region-modal/index.jsx";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useAutomatedProjectContracts from "~/src/hooks/use-automated-project-contracts.js";
import useModal from "~/src/hooks/use-modal.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const AutomatedProjectContractsPage = () => {
  const { id } = useParams();
  const { automated_project: project, mutate: mutateProject } = useAutomatedProject(id);

  const [residential, setResidential] = useState(true);
  const [filterSeller, setFilterSeller] = useState(true);

  const {
    automated_contracts: contracts, isError, isLoading, mutate
  } = useAutomatedProjectContracts({
    filterSeller,
    projectId: id,
    residential
  });
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    hide: hideNewContractRegionModal, show: showNewContractRegionModal, visible: contractRegionModalVisible
  } = useModal();
  const {
    data: deleteContractRegionModalData, hide: hideDeleteContractRegionModal, show: showDeleteContractRegionModal, visible: deleteContractRegionModalVisible
  } = useModal();

  /**
   *
   * @example
   */
  async function handleRefresh() {
    try {
      setIsRefreshing(true);

      await API.get(`/automated-projects/${id}/search-contracts`);

      mutateProject();
      mutate();
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsRefreshing(false);
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <>
      <NewContractRegionModal hide={hideNewContractRegionModal} mutateProject={mutateProject} projectId={project?.id} visible={contractRegionModalVisible} />

      <DeleteContractRegionModal contractRegion={deleteContractRegionModalData} hide={hideDeleteContractRegionModal} mutateContracts={mutate} mutateProject={mutateProject} visible={deleteContractRegionModalVisible} />

      <div className="flex items-start justify-between">
        <h2 className="text-xl font-medium text-gray-800">Kaufverträge</h2>

        <div className="mt-2 space-y-1.5">
          <div className="flex items-center justify-end space-x-2">
            <p className="text-xs">in Verwendung</p>

            <div className="flex w-5 items-center justify-center rounded-sm bg-green-300 py-0.5 text-green-700">
              <CircleCheckIcon className="size-3.5" />
            </div>
          </div>

          <div className="flex items-center justify-end space-x-2">
            <p className="text-xs">Änderungen vorhanden</p>

            <div className="flex w-5 items-center justify-center rounded-sm bg-amber-300 py-0.5 text-amber-700">
              <CircleCheckIcon className="size-3.5" />
            </div>
          </div>

          <div className="flex items-center justify-end space-x-2">
            <p className="text-xs">nicht in Verwendung</p>

            <div className="flex w-5 items-center justify-center rounded-sm bg-pink-300 py-0.5 text-pink-700">
              <CircleXIcon className="size-3.5" />
            </div>
          </div>

        </div>
      </div>

      <div className="mt-10 flex justify-between">
        <div className="flex flex-col items-start gap-4">
          <button
            disabled={isRefreshing}
            onClick={handleRefresh}
            type="button"
            className={cn("inline-flex justify-center items-center w-32 h-8 border border-transparent shadow-xs text-base font-medium rounded-sm text-white bg-gray-700 focus:outline-hidden focus:ring-0", {
              "hover:bg-gray-800": !isRefreshing
            })}
          >
            {!isRefreshing &&
              <span>Aktualisieren</span>}

            <RefreshCcwIcon
              className={cn("w-5 h-5", {
                "animate-spin cursor-not-allowed": isRefreshing,
                hidden: !isRefreshing
              })}
            />
          </button>

          <Field>
            <div className="flex items-center justify-center gap-4">
              <Label className="w-20 text-right">Andere KV</Label>

              <Switch
                checked={residential}
                onChange={setResidential}
                className={cn(
                  "z-0 relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-hidden focus:ring-2 focus:ring-offset-2",
                  {
                    "bg-emerald-300 focus:ring-emerald-200": residential,
                    "bg-gray-300 focus:ring-gray-200": !residential
                  }
                )}
              >
                <span
                  className={cn(
                    "inline-block h-5 w-5 rounded-full bg-white shadow-sm transform ring-0 transition ease-in-out duration-50",
                    {
                      "translate-x-0": !residential,
                      "translate-x-5": residential
                    }
                  )}
                />
              </Switch>

              <Label>Wohn-KV</Label>
            </div>
          </Field>

          <Field>
            <div className="flex items-center justify-center gap-4">
              <Label className="w-20 text-right">Andere KV</Label>

              <Switch
                checked={filterSeller}
                onChange={setFilterSeller}
                className={cn(
                  "z-0 relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-hidden focus:ring-2 focus:ring-offset-2",
                  {
                    "bg-emerald-300 focus:ring-emerald-200": filterSeller,
                    "bg-gray-300 focus:ring-gray-200": !filterSeller
                  }
                )}
              >
                <span
                  className={cn(
                    "inline-block h-5 w-5 rounded-full bg-white shadow-sm transform ring-0 transition ease-in-out duration-50",
                    {
                      "translate-x-0": !filterSeller,
                      "translate-x-5": filterSeller
                    }
                  )}
                />
              </Switch>

              <Label>Projektverkäufer-KV</Label>
            </div>
          </Field>
        </div>

        <div className="col-span-2">
          <div className="flex justify-between">
            <h2 className="mt-0.5 text-lg font-medium text-gray-800">KG/EZ Suche</h2>
          </div>

          <ul className="mt-0.5">
            <div className="grid grid-cols-6 space-x-4 text-sm">
              <p className="col-span-5">KG: {project?.kgnr}, EZ: {project?.ez}</p>
            </div>
          </ul>
        </div>
      </div>

      <ProjectContractList contracts={contracts} project={project} />
    </>
  );
};

export default AutomatedProjectContractsPage;
