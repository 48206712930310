import { useParams } from "react-router-dom";

import ProjectDocuments from "~/src/features/project-documents/index.jsx";

import useProject from "~/src/hooks/use-project.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const ProjectDocumentsPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <ProjectDocuments />
  );
};

export default ProjectDocumentsPage;
