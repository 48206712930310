import cn from "classnames";
import { useParams } from "react-router-dom";

import { formatNumber } from "~/src/modules/formatters.js";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import EnergyClassSlide from "./energy-class-slide.jsx";
import { handleDelete } from "./handlers.js";

const ProjectEnergyClasses = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  return (
    <TabListContainer>
      <EnergyClassSlide energy_class={slideData} hide={hideSlide} projectId={id} visible={slideVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Energieausweis" />

        <DescriptionContainer>

          {project.energy_classes.map((energy_class, index) => (

            <div
              key={`energy_class_${energy_class.id}`}
              className={cn("grid grid-cols-5 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">HWB</p>

                <p className="">{formatNumber(energy_class.hwb_from)} {energy_class.hwb_to ? `- ${formatNumber(energy_class.hwb_to)}` : null}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">HWB Klasse</p>

                <p className="">{energy_class.hwb_class}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">fGEE</p>

                <p className="">{formatNumber(energy_class.fgee_from)} {energy_class.fgee_to ? `- ${formatNumber(energy_class.fgee_to)}` : null}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">fGEE Klasse</p>

                <p className="">{energy_class.fgee_class}</p>
              </div>

              {showEditActions
                ? (
                  <div className="col-span-1 flex items-center justify-end">
                    <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showSlide(energy_class)} type="button">
                      <EditIcon className="size-6" />
                    </button>

                    <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showDelete(energy_class)} type="button">
                      <DeleteIcon className="size-6" />
                    </button>
                  </div>
                )
                : null}

              <div className="col-span-3">
                <p className="text-sm font-medium text-gray-700">Grundstücke</p>

                <p className="">{energy_class.gst_numbers.join(", ")}</p>
              </div>

              <div className="col-span-3">
                <p className="text-sm font-medium text-gray-700">Bereich/Objekt</p>

                <p className="">{energy_class.description}</p>
              </div>

            </div>
          ))}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide()}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectEnergyClasses;
