import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";

const EditorInput = ({
  control,
  name,
  value: defaultValue
}) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    render={({ field: { onChange, value } }) => (
      <ReactQuill
        bounds="[data-slide]"
        formats={formats}
        modules={modules}
        onChange={onChange}
        theme="snow"
        value={value}
      />
    )}
  />
);

export default EditorInput;

const modules = {
  toolbar: [
    [
      {
        header: [
          2,
          3,
          false
        ]
      }
    ],
    [
      "bold",
      "italic",
      "underline",
      "blockquote"
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link"
];
