import { omit, pick } from "radashi";

import initialValues from "../_common/initial-values.js";

const generateEditValues = (issuer) => {
  const {
    certificates
  } = issuer;

  return {
    ...initialValues,
    ...(
      omit(
        issuer,
        [
          "created_at",
          "deleted_at",
          "updated_at",
          "id",
          "certificates"
        ]
      )
    ),
    certificates: certificates.length > 0
      ? certificates.map((certificate) => pick(
        certificate,
        [
          "name",
          "rank",
          "id"
        ]
      ))
      : initialValues.certificates
  };
};

export default generateEditValues;
