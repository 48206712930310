import Joi from "joi";

const schema = Joi.object({
  max_points: Joi
    .when("has_points", {
      is: true,
      otherwise: Joi.any(),
      then: Joi
        .number()
        .empty(
          Joi
            .any()
            .valid(null, "")
        )
        .required()
        .positive()
        .integer()
        .max(10_000)
    }),
  certificates: Joi.array()
    .when("has_certificates", {
      is: true,
      otherwise: Joi.any(),
      then: Joi.array()
        .items(
          Joi.object({
            id: Joi
              .number()
              .empty(null),
            name: Joi
              .string()
              .empty("")
              .required(),
            rank: Joi
              .number()
              .min(1)
              .empty(null)
              .required()
          })
        )
    }),
  has_certificates: Joi.boolean(),
  has_points: Joi
    .boolean()
    .default(false),
  internal_notes: Joi
    .string()
    .empty("")
    .allow(null)
    .max(500)
    .default(null),
  label: Joi
    .string()
    .empty(""),
  name: Joi
    .string()
    .empty("")
    .required()
    .trim(),
  searchable: Joi
    .boolean()
    .default(false)
});

export default schema;
