import { Fragment } from "react";
import { Link } from "react-router-dom";

import { formatDate } from "~/src/modules/formatters.js";

import useAuth from "~/src/hooks/use-auth.js";

import LockBadgeSmall from "~/src/ui/badges/lock-badge-small/index.jsx";
import Badge from "~/src/ui/badges/status-badge/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";
import ProjectLink from "~/src/ui/project-link/index.jsx";
import ProjectLocation from "~/src/ui/project-location/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.automated - The root object
 * @param props0.isLoading - The root object
 * @param props0.projects - The root object
 * @example
 */
const ProjectList = ({
  automated = false, isLoading, projects
}) => {
  const { user } = useAuth();

  if (isLoading) {
    return <Skeleton />;
  }

  const route = automated ? "automated-projects" : "projects";

  return (
    <div className="my-6 grid grid-cols-2 overflow-hidden rounded-xs border-2 border-gray-200 md:grid-cols-1">

      <div className="grid-cols-16 hidden border-b-2 border-gray-200 md:grid">

        <div className="col-span-1 grid grid-cols-8 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 md:col-span-6">
          <div className="col-span-2 bg-gray-100 pr-1.5 text-left text-xs font-bold uppercase tracking-wider text-gray-500 xl:col-span-1">
            <p>ID</p>
          </div>

          <p>Name</p>
        </div>

        <div className="col-span-1 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 md:col-span-5 xl:col-span-6">
          <p>Adresse</p>
        </div>

        <div className="col-span-3 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 xl:col-span-2">
          <p>Bearbeitet</p>
        </div>

        <div className="col-span-2 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500">
          <p>Status</p>
        </div>

      </div>

      <div className="col-span-2 grid grid-cols-1 divide-y-2 divide-gray-200 md:col-span-1">
        {projects?.map((project) => {
          const isProjectEditor = project?.active_project_editor && project.active_project_editor.email === user?.email;
          const isProjectLocked = Boolean(project?.active_project_editor);
          const isUnitEditor = project?.active_unit_editor && project.active_unit_editor.email === user?.email;
          const isUnitLocked = Boolean(project?.active_unit_editor);

          return (
            <div className="md:grid-cols-16 col-span-1 flex flex-col truncate md:grid" key={`projects_${project.id}`}>
              <div className="relative col-span-1 grid grid-cols-8 truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-6">
                <div className="absolute right-4 col-span-2 whitespace-nowrap py-4 pl-4 pr-1.5 text-sm font-medium text-gray-500 md:static md:p-0 xl:col-span-1">
                  {project?.id}
                </div>

                <div>
                  <ProjectLink
                    {...{
                      automated,
                      project
                    }}
                  />

                  {isProjectLocked
                    ? (
                      <div className="mt-1 flex items-center">
                        <LockBadgeSmall isEditor={isProjectEditor} isLocked={isProjectLocked} />

                        <span className="ml-1">{project?.active_project_editor?.username} (Projekterfassung)</span>
                      </div>
                    )
                    : null}

                  {isUnitLocked
                    ? (
                      <div className="mt-1 flex items-center">
                        <LockBadgeSmall isEditor={isUnitEditor} isLocked={isUnitLocked} />

                        <span className="ml-1">{project?.active_unit_editor?.username} (Objekterfassung)</span>
                      </div>
                    )
                    : null}
                </div>
              </div>

              <div className="col-span-1 whitespace-nowrap px-4 py-1 text-left text-sm text-gray-500 md:col-span-5 md:py-4 xl:col-span-6">
                {
                  [...project?.locations?.slice(0, 3)]
                    .sort(({ default_location: defaultA }, { default_location: defaultB }) => (defaultA ? -1 : 0))
                    .map((location, index, array) => (
                      <Fragment key={`location_${location.id}`}>
                        <ProjectLocation location={location} />

                        {(index === array.length - 1 && project?.locations.length > array.length) && (
                          <Link className="underline" to={`/${route}/${project.id}/project-infos`}>...</Link>
                        )}
                      </Fragment>
                    ))
                }
              </div>

              <div className="col-span-3 whitespace-nowrap px-4 py-1 text-left text-sm text-gray-500 md:col-span-3 md:py-4 xl:col-span-2">
                <p>
                  {formatDate({
                    date: project.updated_at,
                    withTime: true
                  })}
                </p>

                {project?.last_review_should_filter
                  ? (
                    <div className="mt-2">
                      <p className="text-xs font-medium text-gray-500">Letzte Projektkontrolle</p>

                      <p>
                        {project.last_review_date
                          ? formatDate({
                            date: project.last_review_date,
                            withTime: true
                          })
                          : "-"}
                      </p>
                    </div>
                  )
                  : null}

                {project?.last_unit_review_should_filter
                  ? (
                    <div className="mt-2">
                      <p className="text-xs font-medium text-gray-500">Letzte Objektkontrolle</p>

                      <p>
                        {project.last_unit_offer_review_date
                          ? formatDate({
                            date: project.last_unit_offer_review_date,
                            withTime: true
                          })
                          : "-"}
                      </p>
                    </div>
                  )
                  : null}

              </div>

              <div className="col-span-1 flex flex-col items-start gap-1 whitespace-nowrap px-4 pb-2 pt-1 text-left text-sm text-gray-500 md:col-span-2 md:py-4">
                {
                  project.published
                    ? <Badge badgeFor="public" text="Öffentlich" />
                    : <Badge badgeFor="internal" text="Intern" />
                }

                {(project.published && project.unpublished_changes) ? <Badge badgeFor="changes" text="Offene Änderungen" /> : null}
              </div>

            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectList;
