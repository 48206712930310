import { useFormContext } from "react-hook-form";

import AreaField from "~/src/ui/forms/area-field/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import DateField from "~/src/ui/forms/date-field/index.jsx";
import DateInput from "~/src/ui/forms/date-input/index.new.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import MultiField from "~/src/ui/forms/multi-field/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.new.jsx";

import initialValues from "../../_common/initial-values.js";
import BuyerForm from "../buyer-form/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.isEdit - The root object
 * @param props0.unit - The root object
 * @example
 */
const SaleForm = ({ isEdit, unit }) => {
  const {
    clearErrors, control, formState: { errors }, handleSubmit, register
  } = useFormContext();

  return (
    <div className="border-primary-green rounded-sm border-t-2 pt-6">
      <h2 className="ml-6 text-xl font-medium">Verkauf</h2>

      {/* <Field name="kvUrl" label="KV-Detail Link" {...{ register, errors, control }} /> */}
      <Field
        label="KV-Detail ID"
        name="kvId"
        {...{
          control,
          errors,
          register
        }}
      />

      <MultiField id="tzNumber" label="TZ * / Jahr *">
        <NumberInput
          int
          name="tzNumber"
          placeholder="TZ *"
          thousandSeparator=""
          {...{
            control,
            errors,
            register
          }}
        />

        <DateInput
          name="tzYear"
          placeholder="Jahr *"
          {...{
            control,
            errors,
            register
          }}
          defaultValue={initialValues.tzYear}
          format="year"
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
          minDate={new Date("2000-01-01")}
        />
      </MultiField>

      <CheckboxField
        label="TZ ausblenden (außerbücherlicher Eigentümer)"
        name="hideTz"
        {...{
          errors,
          register
        }}
      />

      <DateField
        label="KV-Datum *"
        name="saleDate"
        {...{
          control,
          errors,
          register
        }}
        defaultValue={initialValues.saleDate}
        format="year"
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
        minDate={new Date("2000-01-01")}
        onChange={(e) => {
          handleSubmit(
            () => { },
            (innerErrors) => {
              clearErrors(Object.keys(innerErrors).filter((key) => key !== "verwertetDate"));
            }
          )({ saleDate: e });
        }}
      />

      <MultiField id="salePriceNet" label={<>Verkaufspreis<br />exkl. USt * / inkl. USt</>}>
        <NumberInput
          name="salePriceNet"
          placeholder="Verkaufspreis exkl. USt *"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          name="salePriceGross"
          placeholder="Verkaufspreis inkl. USt"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <NumberField
        area
        label="Fläche"
        name="saleArea"
        {...{
          control,
          errors,
          register
        }}
      />

      <NumberField
        int
        label="Zimmer"
        name="saleRoomCount"
        {...{
          control,
          errors,
          register
        }}
      />

      <MultiField id="saleLoggiaCount" label="Loggia Anzahl / Fläche">
        <NumberInput
          int
          name="saleLoggiaCount"
          placeholder="Loggia Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="saleLoggiaArea"
          placeholder="Loggia Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="saleBalkonCount" label="Balkon Anzahl / Fläche">
        <NumberInput
          int
          name="saleBalkonCount"
          placeholder="Balkon Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="saleBalkonArea"
          placeholder="Balkon Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="saleGartenCount" label="Garten Anzahl / Fläche">
        <NumberInput
          int
          name="saleGartenCount"
          placeholder="Garten Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="saleGartenArea"
          placeholder="Garten Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="saleTerrasseCount" label="Terrasse Anzahl / Fläche">
        <NumberInput
          int
          name="saleTerrasseCount"
          placeholder="Terrasse Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="saleTerrasseArea"
          placeholder="Terrasse Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="saleKellerCount" label="Keller Anzahl / Fläche">
        <NumberInput
          int
          name="saleKellerCount"
          placeholder="Keller Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="saleKellerArea"
          placeholder="Keller Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <AreaField
        label="Sonstige Bemerkungen"
        name="saleNotice"
        {...{
          control,
          errors,
          register
        }}
      />

      <AreaField
        label="Interne Notizen"
        name="internalSaleNotes"
        {...{
          control,
          errors,
          register
        }}
      />

      <BuyerForm
        {...{
          isEdit,
          unit
        }}
      />
    </div>
  );
};

export default SaleForm;
