/* eslint-disable max-lines-per-function */
import cn from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";

import API from "~/src/modules/api.js";
import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import AreaField from "~/src/ui/forms/area-field/index.jsx";
import CollectionField from "~/src/ui/forms/collection-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.new.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";

import { initialValues } from "./_common/_exports.js";
import {
  generateEditValues, handler, schema
} from "./certificate-issuer-form/_exports.js";

const {
  handleCreate,
  handleUpdate
} = handler;

const CertificateIssuerForm = ({
  close,
  issuer,
  mode,
  mutate
}) => {
  const defaultValues = (issuer === undefined)
    ? initialValues
    : generateEditValues(issuer);

  const { showError, showSuccess } = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    resetField,
    setValue,
    watch
  } = useForm({
    defaultValues,
    resolver: validationResolver(schema)
  });

  const onSubmit = (data) => {
    switch (mode) {
      case "create":
        handleCreate({
          data,
          hide: close,
          mutate,
          setIsLoading: setIsSubmitting,
          showError,
          showSuccess
        });
        break;
      case "edit":
        handleUpdate({
          id: issuer.id,
          data,
          hide: close,
          mutate,
          setIsLoading: setIsSubmitting,
          showError,
          showSuccess
        });
        break;
      default:
        // no default
    }
  };

  const hasCertificates = watch("has_certificates");
  const hasPoints = watch("has_points");
  const certificates = watch("certificates");

  const disabled = mode === "view";

  return (
    <form className="flex w-full flex-col items-start gap-4" onSubmit={handleSubmit(onSubmit)}>
      <InlineCheckbox
        label="Aussteller suchbar?"
        name="searchable"
        {...{
          errors,
          register
        }}
        disabled={disabled}
      />

      <Field
        inline
        className="w-full"
        label="Ausstellername"
        name="name"
        {...{
          errors,
          register
        }}
        disabled={disabled}
      />

      <InlineCheckbox
        label="Zertifikate vorhanden"
        name="has_certificates"
        {...{
          errors,
          register
        }}
        disabled={disabled}
      />

      {
        hasCertificates
          ? (
            <CollectionField
              inline
              multiple
              addPosition="bottom"
              className="w-full"
              insertMethod="append"
              label="Zertifikate"
              name="certificates"
              deleteDisabled={(item, index) => (
                certificates.length === 1 ||
                (typeof certificates[index]?.id === "number")
              )}
              inputs={[
                {
                  label: "Zertifikatsname",
                  name: "name",
                  span: 2
                },
                {
                  label: "Position",
                  name: "rank",
                  numberType: "int",
                  span: 2,
                  type: "number"
                }
              ]}
              prefix={(
                <li className="-mb-4 flex w-full gap-4 text-sm font-medium text-gray-900">
                  <div
                    className={cn(
                      "relative grid w-full grid-cols-4 gap-4",
                      {
                        "pr-17": !disabled
                      }
                    )}
                  >
                    <span className="col-span-2">Zertifikatsname</span>

                    <span className="col-span-2">Position</span>
                  </div>
                </li>
              )}
              {...{
                control,
                errors,
                handleSubmit,
                isEdit: true,
                register,
                resetField,
                setValue,
                watch
              }}
              disabled={disabled}
            />
          )
          : null
      }

      <InlineCheckbox
        label="Punktesystem vorhanden"
        name="has_points"
        {...{
          errors,
          register
        }}
        disabled={disabled}
      />

      {
        hasPoints
          ? (
            <NumberField
              inline
              int
              spanLabel
              className="w-full"
              label="Maximal verfügbare Punkte"
              name="max_points"
              {...{
                control,
                errors,
                register
              }}
              disabled={disabled}
            />
          )
          : null
      }

      <AreaField
        inline
        className="w-full"
        label="Interne Bemerkungen"
        name="internal_notes"
        {...{
          errors,
          register
        }}
        disabled={disabled}
      />

      <div className="flex w-full gap-2">
        <Button
          className="w-full"
          disabled={isSubmitting}
          label={disabled ? "Schließen" : "Abbrechen"}
          onClick={close}
          variant="outlined"
        />

        {
          !disabled && (
            <Button
              className="w-full"
              disabled={isSubmitting}
              label="Speichern"
              type="submit"
            />
          )
        }
      </div>

    </form>
  );
};

export default CertificateIssuerForm;
