/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.color - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.onClick - The root object
 * @example
 */
const ModalSubmitButton = ({
  children, color = "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500", isSubmitting, onClick
}) => (
  <button
    className={`${color} inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-xs focus:outline-hidden focus:ring-2 focus:ring-offset-2`}
    disabled={isSubmitting}
    onClick={onClick}
    type="button"
  >

    {isSubmitting
      ? (
        <svg className="-ml-1 mr-3 size-5 animate-spin text-white" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />

          <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
        </svg>
      )
      : null}

    {children}
  </button>
);

export default ModalSubmitButton;
