import cn from "classnames";
import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";

// import ShowIcon from .jsx"~/src/ui/icons/show-icon"
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

// import ProjectCompanySlide from '../project-company-slide'
import EditCompanyRoleSlide from "../edit-company-role-slide.jsx";
import { handleDelete } from "../handlers.js";
import NewCompanyRoleSlide from "../new-company-role-slide.jsx";

const ProjectBuilders = ({ showNewCompanyModal }) => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    hide: hideNew, show: showNew, visible: newVisible
  } = useModal();
  const {
    data: editData, hide: hideEdit, show: showEdit, visible: editVisible
  } = useModal();
  // const { visible: detailsVisible, show: showDetails, hide: hideDetails, data: detailsData } = useModal()
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const roles = project?.company_roles.filter((role) => role.name === "builder") || [];

  const showEditActions = editPossible;

  return (
    <TabListContainer>

      <NewCompanyRoleSlide hide={hideNew} projectId={id} roleName="builder" showNewCompanyModal={showNewCompanyModal} visible={newVisible} />

      <EditCompanyRoleSlide hide={hideEdit} projectId={id} role={editData} showEditActions={showEditActions} showNewCompanyModal={showNewCompanyModal} visible={editVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Bauträger" />

        <DescriptionContainer>

          {roles.map((role, index) => (

            <div
              key={`role_${role.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">{role?.company?.is_zvr ? "ZVR-Nr." : "FB-Nr."}</p>

                <p className="">{role?.company?.reg_number}</p>
              </div>

              <div className="col-span-2">
                <p className="text-sm font-medium text-gray-700">{role?.company?.is_zvr ? "Vereinsname" : "Firmenname"}</p>

                <p className="">{role.private ? "Privat" : role?.company?.name}</p>
              </div>

              <div className="col-span-1 flex items-center justify-end">
                {/* <button type="button" onClick={() => showDetails(role)} className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden">
                  <ShowIcon className="h-6 w-6" />
                </button> */}

                {showEditActions
                  ? (
                    <>
                      <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showEdit(role)} type="button">
                        <EditIcon className="size-6" />
                      </button>

                      <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showDelete(role)} type="button">
                        <DeleteIcon className="size-6" />
                      </button>
                    </>
                  )
                  : null}
              </div>

            </div>
          ))}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={showNew}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectBuilders;
