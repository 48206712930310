import cn from "classnames";
import { parseISO } from "date-fns";
import de from "date-fns/locale/de";
import { forwardRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller } from "react-hook-form";

import { formatDate } from "~/src/modules/formatters.new.js";

import Button from "~/src/ui/buttons/button/index.jsx";

registerLocale("de", de);

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.minDate - The root object
 * @param props0.maxDate - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.control - The root object
 * @param props0.defaultValue - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.format - The root object
 * @param props0.isClearable - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.range - The root object
 * @param props0.register - The root object
 * @param props0.type - The root object
 * @example
 */
const DateInput = ({
  minDate = new Date("1900-01-01"),
  maxDate = getTodayInHoundredYears(),
  autoFocus = false,
  className,
  control,
  defaultValue = new Date(),
  disabled = false,
  errors,
  format,
  name,
  onChange = () => { },
  placeholder = "",
  range,
  register,
  type = "search",

  id = name,
  isClearable = range
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [displayValue, setDisplayValue] = useState("Datumsbereich auswählen");

  const showError = Boolean(errors[name]);

  const dateFormat = format === "year" ? "yyyy" : "dd.MM.yyyy";

  const showYearSelects = format === "year";

  const DateButton = forwardRef(({ onClick, value }, reference) => (
    <Button
      {...{
        innerRef: reference,
        onClick
      }}
      label={value}
    />
  ));

  const handleChange = (dateOrDates) => {
    if (range) {
      if (dateOrDates) {
        const [start, end] = dateOrDates;

        setStartDate(start);
        setEndDate(end);

        if (start && end) {
          setDisplayValue([start, end].map((date) => formatDate({ date })).join("-"));
        }
        else if (start === null && end === null) {
          setDisplayValue("Datumsbereich");
        }
      }
      else {
        setDisplayValue("Datumsbereich");
      }
    }
  };

  return (
    <div className={cn("relative", className)}>
      <Controller
        control={control}
        id={id}
        name={name}
        render={({ field: { onChange: innerOnChange, value } }) => {
          let sanitizedValue = Array.isArray(value) ? value[0] : value;

          if (typeof value === "string") {
            const parsedValue = parseISO(value);

            sanitizedValue = parsedValue instanceof Date && !isNaN(parsedValue) ? parsedValue : null;
          }

          return (
            <DatePicker
              autoFocus={autoFocus}
              clearButtonClassName="h-4! w-4! flex! flex-col! justify-center! items-center! -translate-y-2/4 p-0! rounded-full right-2! top-2/4! after:bg-gray-800! after:content-['×']!"
              customInput={range ? <DateButton /> : null}
              dateFormat={dateFormat}
              disabled={disabled}
              dropdownMode="select"
              endDate={endDate}
              id={id}
              isClearable={isClearable ? value?.getTime() !== defaultValue?.getTime() : null}
              locale="de"
              maxDate={maxDate}
              minDate={minDate}
              peekNextMonth={!showYearSelects}
              placeholderText={placeholder}
              selected={sanitizedValue}
              selectsRange={range}
              shouldCloseOnSelect={!range || (startDate && !endDate)}
              showMonthDropdown={!showYearSelects}
              showYearDropdown={!showYearSelects}
              showYearPicker={showYearSelects}
              startDate={startDate}
              value={range ? displayValue : null}
              className={cn("block w-full border rounded-xs shadow-xs h-10 px-3 focus:outline-hidden sm:text-sm placeholder-gray-300", {
                "bg-gray-200 cursor-not-allowed": disabled,
                "bg-white": !disabled,
                "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
                "border-red-500": showError
              })}
              onChange={(value) => {
                handleChange(value);
                innerOnChange(value);

                if (range) {
                  const safeValue = value ?? [null, null];
                  const [start, end] = safeValue;

                  if ((start && end) || (!start && !end)) {
                    onChange(safeValue);
                  }
                }
                else {
                  onChange(value);
                }
              }}
            />
          );
        }}
      />

      {showError
        ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex h-10 items-center pr-3">
            <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
            </svg>
          </div>
        )
        : null}

      {showError ? <p className="col-span-3 mt-2 text-sm text-red-600">{errors[name].message}</p> : null}
    </div>
  );
};

export default DateInput;

/**
 *
 * @example
 */
function getTodayInHoundredYears() {
  const date = new Date();

  date.setHours(23, 59, 59, 999);
  date.setFullYear(date.getFullYear() + 100);

  return date;
}
