import cn from "classnames";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import API from "~/src/modules/api.js";
import validationResolver from "~/src/modules/validation-resolver.js";

import useModal from "~/src/hooks/use-modal.js";
import useNotification from "~/src/hooks/use-notification.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import UnitDocumentItemSmall from "~/src/ui/project-unit/unit-document-item-small/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import {
  generateEditValues
} from "./_common/_exports.js";
import CreateBulkModal from "./create-bulk-modal.jsx";
import OfferForm from "./forms/offer-form/index.jsx";
import { handleUpdate } from "./handlers.js";
import { initialValuesBulk, schemaBulk } from "./unit-form-slide-bulk/_exports.js";

const EditUnitSlide = ({
  buildingParts, hide, mutateUnits, projectId, unit, visible
}) => {
  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    data: bulkData, hide: hideBulkCreate, show: showBulkCreate, visible: bulkCreateVisible
  } = useModal();

  const { showError, showSuccess } = useNotification();

  const form = useForm({
    defaultValues: initialValuesBulk,
    resolver: validationResolver(schemaBulk)
  });

  const {
    control, formState: { errors, isDirty }, handleSubmit, register, reset, resetField, setValue, watch
  } = form;

  useEffect(() => {
    if (unit) {
      setIsEdit(true);
      generateEditValues(unit)
        .then((editValues) => {
          reset(editValues);
        });
    }
  }, [reset, unit]);

  const formValues = watch();

  const {
    offerDataAvailable
  } = formValues;

  const handleClose = () => {
    reset(initialValuesBulk);
    hide();
  };

  const onSubmit = (data) => {
    if (isDirty) {
      if (unit) {
        handleUpdate({
          id: unit.id,
          data,
          handleClose,
          mutate: mutateUnits,
          setIsLoading,
          showError,
          showSuccess
        });
      }
      else {
        showBulkCreate({
          formValues,
          handleClose,
          mutate: mutateUnits,
          projectId,
          setIsLoading,
          showError,
          showSuccess
        });
      }
    }
    else {
      handleClose();
    }
  };

  const loadBuildingPartOptions = async (inputValue) => API.get(`/projects/${projectId}/building-parts`).then((res) => res.data.payload.building_parts
    .map((part) => ({
      id: part.id,
      label: <p><span className="inline-block w-16 px-1 font-bold text-yellow-500">{Number.parseFloat(part.sorting)}</span>Bauteil: {part.name}</p>,
      name: part.name
    }))
    .filter((part) => part.name.includes(inputValue))).catch((error) => {
    console.log(error);
  });

  const loadUnitCategoryOptions = async (inputValue) => {
    const { data: { payload: { quantities } } } = await API.get(`/projects/${projectId}/quantities`);

    const quantityCategories = new Set(quantities.map(({ unit_category_id }) => unit_category_id));

    return API.get("/unit-categories")
      .then((res) => {
        let options = res.data.payload.unit_categories;

        options = options
          .filter((category) => quantityCategories.has(category.id))
          .map((category) => ({
            id: category.id,
            code: category.code,
            label: category.name
          }))
          .filter((category) => category.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) || inputValue.toLocaleLowerCase().includes(category.label.toLocaleLowerCase()));

        return options;
      }).catch((error) => {
        console.log(error);
      });
  };

  const disableOnData = !(offerDataAvailable);

  return (
    <div>
      <CreateBulkModal data={bulkData} hide={hideBulkCreate} show={bulkCreateVisible} watchData={{ formValues }} />

      <SlideOver hide={handleClose} visible={visible}>
        <FormProvider {...form}>
          <form autoComplete="off" className="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" value="hidden" />

            <div className="flex flex-1 flex-col">

              <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Mehrere Objekte ${unit ? "bearbeiten" : "erstellen"}`} />

              <CheckboxField
                label="Objekt im Produkt anzeigen"
                name="show"
                {...{
                  errors,
                  register
                }}
              />

              <div className="m-4 grid grid-cols-6 gap-x-4 bg-indigo-100 px-3 py-5">
                <div className="col-span-6">
                  <p className="text-sm font-bold text-indigo-600">Interne Sortierung</p>
                </div>

                <ComboField
                  async
                  loadDefaultComboOptions
                  label="Bauteil"
                  name="buildingPart"
                  options={loadBuildingPartOptions}
                  messages={{
                    queryEmpty: "Bauteil..."
                  }}
                  {...{
                    control,
                    isEdit,
                    resetField,
                    setValue,
                    watch
                  }}
                  compact
                  className="col-span-6"
                />

                <NumberField
                  noprefix
                  label="Stiege"
                  name="positionStaircase"
                  scale="none"
                  {...{
                    control,
                    errors,
                    register
                  }}
                  compact
                  className="col-span-3"
                  innerClassName="pr-0"
                />

                <div className="col-span-3 space-y-3">
                  <NumberField
                    noprefix
                    label={<span className="text-xs">Nummer (von)</span>}
                    name="positionFrom"
                    scale="none"
                    {...{
                      control,
                      errors,
                      register
                    }}
                    compact
                    className="col-span-3 ml-8"
                    innerClassName="pl-0"
                  />

                  <NumberField
                    noprefix
                    label={<span className="text-xs">Nummer (bis)</span>}
                    name="positionTo"
                    scale="none"
                    {...{
                      control,
                      errors,
                      register
                    }}
                    compact
                    className="col-span-3 ml-8"
                    innerClassName="pl-0"
                  />
                </div>
              </div>

              <ComboField
                async
                loadDefaultComboOptions
                label="Objektkategorie"
                name="unit_category"
                options={loadUnitCategoryOptions}
                messages={{
                  queryEmpty: "Objektkategorie..."
                }}
                {...{
                  control,
                  isEdit,
                  resetField,
                  setValue,
                  watch
                }}
                className="col-span-6"
              />

              <Field
                label="Name"
                name="name"
                {...{
                  errors,
                  register
                }}
              />

              <Field
                label="Stiege"
                name="staircase"
                {...{
                  errors,
                  register
                }}
              />

              <Field
                label="Geschoß"
                name="floor"
                {...{
                  errors,
                  register
                }}
              />

              <CheckboxField
                label="Maisonettewohnung"
                name="maisonette"
                {...{
                  errors,
                  register
                }}
              />

              <div
                className={cn("m-4 transition-all rounded-sm", {
                  "bg-primary-brown-light": offerDataAvailable,
                  "bg-white": !offerDataAvailable
                })}
              >
                <CheckboxField
                  label="Angebotsdaten vorhanden"
                  name="offerDataAvailable"
                  className={cn("transition-all transform", {
                    "-mx-4": !offerDataAvailable,
                    "mx-0": offerDataAvailable
                  })}
                  {...{
                    errors,
                    register
                  }}
                />

                {offerDataAvailable
                  ? (
                    <OfferForm
                      {...{
                        isEdit,
                        onSubmit
                      }}
                    />
                  )
                  : null}
              </div>
            </div>

            <div className="sticky bottom-0 flex shrink-0 items-center justify-between gap-4 border-t border-gray-200 bg-white px-4 py-5 sm:px-6">
              {
                unit?.documents && unit.documents.length > 0
                  ? (
                    <ul className="flex flex-wrap gap-2">
                      {
                        unit.documents.map((document) => (
                          <UnitDocumentItemSmall
                            document={document}
                            key={`document_unit_${document.id}`}
                          />
                        ))
                      }
                    </ul>
                  )
                  : null
              }

              <div className="ml-auto flex items-center justify-end gap-4">
                <CancelButton disabled={formIsLoading} onClick={handleClose} />

                <FormSubmitButton disabled={disableOnData} isSubmitting={formIsLoading}>
                  Speichern
                </FormSubmitButton>
              </div>
            </div>

          </form>

        </FormProvider>
      </SlideOver>
    </div>

  );
};

export default EditUnitSlide;
