import { useState } from "react";
import {
  NumberParam, StringParam, useQueryParams, withDefault
} from "use-query-params";

import {
  CertificateIssuerModal,
  CertificateIssuerSearchForm,
  CertificateIssuersList
} from "~/src/features/certificate-issuers/_exports.js";
import Pagination from "~/src/features/pagination/index.jsx";

import useCertificateIssuers from "~/src/hooks/use-certificate-issuers.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";
import PageTitle from "~/src/ui/titles/page-title.jsx";

/**
 * CertificatesPage component renders the ESG certificates page.
 * It includes pagination, search functionality, and modals for adding, editing, and viewing certificate issuers.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component.
 * @example
 * // Usage example:
 * <CertificatesPage />
 */
const CertificateIssuersPage = () => {
  const [query, setQuery] = useQueryParams({
    name: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1)
  });

  const {
    name,
    page
  } = query;

  const setPage = (pageToSet) => {
    setQuery({ page: pageToSet });
  };

  const setName = (nameToSet) => {
    setQuery({ name: nameToSet });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [modalMode, setModalMode] = useState("view");

  const {
    error,
    isLoading,
    issuers,
    mutate,
    pagination
  } = useCertificateIssuers({
    name,
    page
  });

  const handleOpenIssuerModal = ({ issuer, mode }) => {
    setSelectedIssuer(issuer);
    setModalMode(mode);
    setIsModalOpen(true);
  };

  if (error) {
    return null;
  }

  return (
    <Page className="flex w-full flex-col items-start gap-6" title="ESG">

      <CertificateIssuerModal
        {...{
          close: () => {
            setIsModalOpen(false);
          },
          isOpen: isModalOpen,
          issuer: selectedIssuer,
          mode: modalMode,
          mutate
        }}
      />

      <PageHeader className="flex w-full flex-col items-start gap-6">
        <PageTitle>ESG</PageTitle>

        <button
          className="focus:outline-hidden text-sm underline"
          type="button"
          onClick={() => {
            handleOpenIssuerModal({ mode: "create" });
          }}
        >
          Neuen Aussteller hinzufügen
        </button>
      </PageHeader>

      <MainContainer className="flex w-full flex-col gap-6">

        <CertificateIssuerSearchForm
          {...{
            name,
            setName
          }}
        />

        <Pagination
          labels={["Aussteller", "Aussteller"]}
          page={page}
          pagination={pagination}
          setPage={setPage}
        />

        <CertificateIssuersList
          {...{
            isLoading,
            issuers,
            openIssuerModal: handleOpenIssuerModal
          }}
        />

        <Pagination
          labels={["Aussteller", "Aussteller"]}
          page={page}
          pagination={pagination}
          setPage={setPage}
        />

      </MainContainer>

    </Page>
  );
};

export default CertificateIssuersPage;
