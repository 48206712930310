import { useFormContext } from "react-hook-form";

import API from "~/src/modules/api.js";

import { loadLocationOptions } from "~/src/features/project-units/helpers.js";

import CollectionField from "~/src/ui/forms/collection-field/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.isEdit - The root object
 * @param props0.unit - The root object
 * @example
 */
const BuyerForm = ({ isEdit, unit }) => {
  const {
    control, formState: { errors }, handleSubmit, register, resetField, setValue, watch
  } = useFormContext();

  /**
   *
   * @param props0 - The root object
   * @param props0.hereId - The root object
   * @param props0.name - The root object
   * @example
   */
  function autofillAddress({ hereId, name }) {
    if (hereId) {
      API.get(`/locations/lookup/${hereId}`)
        .then((res) => {
          const {
            data: {
              payload: {
                location: {
                  address: {
                    city,
                    countryCode,
                    countryId,
                    countryName,
                    houseNumber,
                    label,
                    postalCode: zipcode,
                    street
                  },
                  position: { lat, lng }
                }
              }
            }
          } = res;

          for (const [key, value] of Object.entries({
            city,
            houseNumber,
            street,
            zipcode
          })) {
            setValue(name.replace(/\.coordinates$/u, `.${key}`), value);
          }

          setValue(
            name.replace(/\.coordinates$/u, ".country"),
            {
              id: countryId,
              code: countryCode,
              label: countryName
            }
          );

          setValue(
            name,
            {
              label,
              lat,
              lng
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const privateBuyers = watch("privateBuyers");
  const companyBuyers = watch("companyBuyers");

  const privateBuyersCountryCodes = privateBuyers?.map(({ country }) => country?.code);
  const companyBuyersCountryCodes = companyBuyers?.map(({ country }) => country?.code);

  const loadCountryOptions = (inputValue) => API.get("/locations/countries").then((res) => {
    const options = res.data.payload.countries.map((country) => ({
      code: country.code,
      label: country.name,
      value: country.id
    }));

    const sortedOptions = [...options]
      .sort(({ label: nameA }, { label: nameB }) => nameA.localeCompare(nameB))
      .sort(({ code }) => (code === "AUT" ? -1 : 1));

    return sortedOptions
      .filter(({ label }) => label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }).catch((error) => {
    console.log(error);
  });

  const resetCoordinates = (name) => {
    setValue(name.replace(/\.[A-Za-z]+$/u, ".coordinates"), null);
  };

  return (
    <div className="border-primary-green border-t-2 pt-6">
      <CollectionField
        lastFieldRemovable
        multiple
        addDisabled={!privateBuyers || privateBuyers.some(({ surname }) => !surname)}
        addLabel="hinzufügen"
        addPosition="bottom"
        className="pb-16"
        insertMethod="append"
        label="Privatkäufer"
        name="privateBuyers"
        aside={
          unit?.buyer && unit.buyer.length > 0
            ? (
              <div className="mt-4 flex w-full flex-col gap-2 rounded-sm bg-white/50 p-4">
                <h3 className="text-sm text-gray-800">Alte Käufereinträge:</h3>

                <ul className="flex flex-col gap-2 text-xs">
                  {
                    unit?.buyer.map((oldBuyerName, index) => (
                      <li className="flex items-center justify-between rounded-sm bg-white/50 p-2 text-gray-600" key={index}>
                        <span>{oldBuyerName}</span>
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
            : null
        }
        inputs={[
          {
            defaultValue: "",
            label: "Titel-Präfix",
            name: "titlePrefix",
            span: 2
          },
          {
            defaultValue: "",
            label: "Titel-Suffix",
            name: "titleSuffix",
            span: 2
          },
          {
            defaultValue: "",
            label: "Vorname",
            name: "name"
          },
          {
            defaultValue: "",
            label: "Nachname *",
            name: "surname"
          },
          {
            defaultValue: "",
            label: "Geburtsdatum",
            name: "birthdate",
            span: 4,
            type: "date"
          },
          {
            async: true,
            countryCodes: privateBuyersCountryCodes,
            defaultValue: null,
            label: "Adresse vorschlagen",
            name: "coordinates",
            onChange: (e, name) => {
              const hereId = e?.id;

              autofillAddress({
                hereId,
                name
              });

              // handleSubmit()(e)
            },
            options: loadLocationOptions,
            span: 4,
            type: "combo"
          },
          {
            async: true,
            defaultOptions: true,
            defaultValue: null,
            label: "Land",
            name: "country",
            onChange: (e, name) => {
              resetCoordinates(name);
            },
            options: loadCountryOptions,
            span: 4,
            type: "combo"
          },
          {
            defaultValue: "",
            label: "Postleitzahl",
            name: "zipcode",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Stadt",
            name: "city",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Straße",
            name: "street",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Hausnummer",
            name: "houseNumber",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Notizen",
            name: "notes",
            span: 4,
            type: "area"
          },
          {
            className: "absolute -bottom-12 h-8 flex justify-center items-center",
            defaultValue: true,
            label: "Käufer anzeigen",
            name: "show",
            type: "checkbox"
          }
        ]}
        {...{
          control,
          errors,
          handleSubmit,
          isEdit,
          register,
          resetField,
          setValue,
          watch
        }}
      />

      <CollectionField
        lastFieldRemovable
        multiple
        addDisabled={!companyBuyers || companyBuyers.some(({ name }) => !name)}
        addLabel="hinzufügen"
        addPosition="bottom"
        insertMethod="append"
        label="Firmenkäufer"
        name="companyBuyers"
        aside={
          unit?.buyer && unit.buyer.length > 0
            ? (
              <div className="mt-4 flex w-full flex-col gap-2 rounded-sm bg-white/50 p-4">
                <h3 className="text-sm text-gray-800">Alte Käufereinträge:</h3>

                <ul className="flex flex-col gap-2 text-xs">
                  {
                    unit?.buyer.map((oldBuyerName, index) => (
                      <li className="flex items-center justify-between rounded-sm bg-white/50 p-2 text-gray-600" key={index}>
                        <span>{oldBuyerName}</span>
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
            : null
        }
        inputs={[
          {
            defaultValue: "",
            label: "Name *",
            name: "name"
          },
          {
            defaultValue: "",
            label: (name) => (watch(name.replace(/\.regNumber$/u, ".isZvr")) ? "ZVR-Nummer" : "Firmenbuch-Nummer"),
            name: "regNumber"
          },
          {
            className: "flex justify-end",
            defaultValue: false,
            label: "Verein",
            name: "isZvr",
            type: "checkbox"
          },
          {
            className: "flex justify-end",
            defaultValue: false,
            label: "Keine FB- oder ZVR-Nr.",
            name: "noRegNumber",
            type: "checkbox"
          },
          {
            async: true,
            countryCodes: companyBuyersCountryCodes,
            defaultValue: null,
            label: "Adresse vorschlagen",
            name: "coordinates",
            onChange: (e, name) => {
              const hereId = e?.id;

              autofillAddress({
                hereId,
                name
              });
            },
            options: loadLocationOptions,
            span: 4,
            type: "combo"
          },
          {
            async: true,
            defaultOptions: true,
            defaultValue: null,
            label: "Land",
            name: "country",
            onChange: (e, name) => {
              resetCoordinates(name);
            },
            options: loadCountryOptions,
            span: 4,
            type: "combo"
          },
          {
            defaultValue: "",
            label: "Postleitzahl",
            name: "zipcode",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Stadt",
            name: "city",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Straße",
            name: "street",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Hausnummer",
            name: "houseNumber",
            onChange: (e, name) => {
              resetCoordinates(name);
            }
          },
          {
            defaultValue: "",
            label: "Notizen",
            name: "notes",
            span: 4,
            type: "area"
          },
          {
            className: "absolute -bottom-12 h-8 flex justify-center items-center",
            defaultValue: true,
            label: "Käufer anzeigen",
            name: "show",
            type: "checkbox"
          }
        ]}
        {...{
          control,
          errors,
          handleSubmit,
          isEdit,
          register,
          resetField,
          setValue,
          watch
        }}
      />
    </div>

  );
};

export default BuyerForm;
