import {
  Disclosure, DisclosureButton, DisclosurePanel
} from "@headlessui/react";
import cn from "classnames";

import { pluralize } from "~/src/modules/formatters.new.js";

import EditProjectEditors from "~/src/features/edit-project-editors/index.jsx";

import { UnitBuyerDifferencesListProjectUnit } from "./project/_exports.js";

const UnitBuyerDifferencesListProject = ({
  id,
  editPossible,
  mutate,
  name,
  units
}) => (
  <Disclosure
    as="li"
    className={cn(
      "flex flex-col items-start px-3 pt-0 border border-gray-300 rounded-sm bg-gray-50 gap-3",
      {
        "data-[headlessui-state=open]:pb-0": units.length === 0,
        "data-[headlessui-state=open]:pb-3": units.length > 0
      }
    )}
  >
    <DisclosureButton as="div" className="sticky top-12 -ml-3 grid w-[calc(100%+1.5rem)] cursor-pointer grid-cols-3 items-center justify-between rounded-sm bg-gray-200 px-3 py-2">
      <a className="flex max-w-full items-center gap-2 justify-self-start" href={`/projects/${id}`}>
        <span className="truncate text-sm font-medium text-gray-600 underline hover:no-underline">{name}</span>

        <span className="shrink-0 rounded-md bg-gray-300 px-2 text-xs font-bold capitalize text-gray-900">ID: {id}</span>
      </a>

      <span className="max-w-full justify-self-center text-center text-sm text-gray-400">{units.length} {pluralize("Objekt", "Objekte", units.length)} betroffen</span>

      <div className="max-w-full justify-self-end text-right">
        <EditProjectEditors onlyUnit small projectId={id} />
      </div>
    </DisclosureButton>

    {
      units.length > 0 && (
        <DisclosurePanel as="ul" className="flex w-full flex-col gap-4">
          {
            units
              .map(({
                id: unitId, differences, kvId, name: unitName
              }) => (
                <UnitBuyerDifferencesListProjectUnit
                  key={unitId}
                  {...{
                    id: unitId,
                    differences,
                    editPossible,
                    kvId,
                    mutate,
                    name: unitName,
                    projectId: id
                  }}
                />
              ))
          }
        </DisclosurePanel>
      )
    }

  </Disclosure>
);

export default UnitBuyerDifferencesListProject;
