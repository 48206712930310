import { EyeOffIcon, PencilIcon } from "lucide-react";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @param props0 - The root object
 * @param props0.companies - The root object
 * @param props0.isLoading - The root object
 * @param props0.showDetailsModal - The root object
 * @param props0.showEditModal - The root object
 * @example
 */
const CompanyList = ({
  companies, isLoading, showDetailsModal, showEditModal
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className="my-6 grid grid-cols-3 overflow-hidden rounded-xs border-2 border-gray-200 md:grid-cols-1">

      <div className="hidden grid-cols-4 border-b-2 border-gray-200 md:grid">

        <div className="col-span-1 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 md:col-span-3">
          <p className="ml-16">Name</p>
        </div>

        <div className="col-span-1 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500">
          <p>Firmenbuch-Nr.</p>
        </div>

      </div>

      <div className="col-span-2 grid grid-cols-1 divide-y-2 divide-gray-200 md:col-span-1">
        {companies?.map((company) => (
          <div className="col-span-1 grid md:grid-cols-4" key={`company_${company.id}`}>

            <div className="col-span-1 flex items-start truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-3">
              <button className="flex items-center font-medium text-gray-500 underline hover:text-gray-600 focus:outline-hidden" onClick={() => showDetailsModal(company)} type="button">
                {!company.searchable &&
                  <EyeOffIcon className="mr-2 size-5 text-red-500 hover:text-red-400" />}

                {company.name}
              </button>
            </div>

            <div className="flex items-center justify-between whitespace-nowrap px-4 py-1 text-left text-sm font-medium text-gray-500 md:py-4">
              <span>
                {company.reg_number
                  ? (
                    <button className="font-medium text-gray-500 underline hover:text-gray-600 focus:outline-hidden" onClick={() => showDetailsModal(company)} type="button">
                      {company.reg_number}
                    </button>
                  )
                  : null}
              </span>

              <div className="ml-4 flex w-12">
                <button className="focus:outline-hidden" onClick={() => showEditModal(company)} type="button">
                  <PencilIcon className="size-5 text-gray-500 hover:text-gray-600" />
                </button>
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyList;
