/**
 * @import { IssuerCertificate } from "./issuer-certificate.doc.js";
 */

/**
 * Represents a certificate issuer.
 *
 * @typedef {object} CertificateIssuer
 * @property {number} id - The unique identifier of the certificate issuer.
 * @property {string} name - The name of the certificate issuer.
 * @property {boolean} has_points - Indicates if the issuer has points.
 * @property {number} max_points - The maximum points the issuer can have.
 * @property {boolean} has_certificates - Indicates if the issuer has certificates.
 * @property {boolean} searchable - Indicates if the issuer is searchable.
 * @property {string} internal_notes - Internal notes about the issuer.
 * @property {readonly IssuerCertificate[]} certificates - The certificates of the issuer.
 */
