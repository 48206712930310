import useSignedSource from "~/src/hooks/use-signed-source.js";

import SVGIcon from "~/src/ui/icons/svg-icon/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.document - The root object
 * @param props0.styles - The root object
 * @example
 */
const SignedImage = ({ document, styles }) => {
  const { src } = useSignedSource(document.file_name);

  if (document?.file_type === "application/pdf") {
    return (
      <a href={src || ""} rel="noreferrer" target="_blank">
        <div className="flex size-full items-center justify-center bg-gray-100 shadow-xs">
          <SVGIcon styles="w-32 h-32" />
        </div>
      </a>
    );
  }

  if (src) {
    return (
      <a href={src} rel="noreferrer" target="_blank">
        <img alt="" className={styles} src={src} />
      </a>
    );
  }

  return null;
};

export default SignedImage;
