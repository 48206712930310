import { DownloadIcon, XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ArrayParam, NumberParam, useQueryParams, withDefault
} from "use-query-params";

import useUnitBuyerDifferences from "~/src/hooks/use-unit-buyer-differences.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";

import Pagination from "../features/pagination/index.jsx";
import { pluralize } from "../modules/formatters.new.js";
import NumberInput from "../ui/forms/number-input/index.new.jsx";
import Select from "../ui/forms/select/index.new.jsx";
import PageTitle from "../ui/titles/page-title.jsx";

import { UnitBuyerDifferencesList } from "./unit-buyer-differences/_exports.js";

const factor = 2;

const minExponent = 6;
const maxExponent = 6;

const minLimit = Math.round(factor ** minExponent);
const maxLimit = Math.round(factor ** maxExponent);

const UnitBuyerDifferencesPage = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    projectId: NumberParam,
    unwantedKeys: withDefault(ArrayParam, [])
  });

  const {
    page: queryPage,
    projectId: queryProjectId,
    unwantedKeys: queryUnwantedKeys
  } = query;

  const [pageNumber, setPageNumber] = useState(1);

  const [unwantedKeys, setUnwantedKeys] = useState([]);

  const [pagination, setPagination] = useState({});
  const [unitBuyerDifferences, setUnitBuyerDifferences] = useState(null);

  const form = useForm({
    defaultValues: {
      unwantedKeys: []
    }
  });

  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = form;

  const unwantedKeysOptions = Object.entries({
    company: [
      ["name", "Name"],
      ["reg_number", "Firmenbuch-Nummer"],
      ["country", "Land"],
      ["zipcode", "Postleitzahl"],
      ["city", "Stadt"],
      ["street", "Straße"],
      ["house_number", "Hausnummer"]
    ],
    private: [
      ["title_prefix", "Titel-Präfix"],
      ["name", "Vorname"],
      ["surname", "Nachname"],
      ["birthdate", "Geburtsdatum"],
      ["country", "Land"],
      ["zipcode", "Postleitzahl"],
      ["city", "Stadt"],
      ["street", "Straße"],
      ["house_number", "Hausnummer"]
    ]
  })
    .flatMap(([key, value]) => value
      .map(([innerKey, innerValue]) => [`${key}:${innerKey}`, key === "private" ? `Privat: ${innerValue}` : `Firma: ${innerValue}`]))
    .map(([value, label], index) => ({
      id: index,
      label,
      value
    }));

  const nextUnwantedKeys = watch("unwantedKeys");

  const projectId = watch("projectId");

  useEffect(() => {
    setUnwantedKeys(nextUnwantedKeys);
  }, [nextUnwantedKeys]);

  const {
    isLoading,
    mutate,
    pagination: currentPagination,
    unitBuyerDifferences: currentBuyerDifferences
  } = useUnitBuyerDifferences({
    pageNumber,
    projectId,
    unwantedKeys: unwantedKeys.map(({ value }) => value)
  });

  useEffect(() => {
    if (currentPagination) {
      setPagination(currentPagination);
    }
  }, [currentPagination]);

  useEffect(() => {
    if (currentBuyerDifferences) {
      setUnitBuyerDifferences(currentBuyerDifferences);
    }
  }, [currentBuyerDifferences]);

  useEffect(() => {
    setQuery({
      page: pageNumber,
      projectId,
      unwantedKeys: unwantedKeys.map(({ value }) => value)
    });
  }, [pageNumber]);

  useEffect(() => {
    setQuery({
      page: 1,
      projectId,
      unwantedKeys: unwantedKeys.map(({ value }) => value)
    });
  }, [unwantedKeys, projectId]);

  useEffect(() => {
    if (queryPage) {
      setPageNumber(queryPage);
    };
    if (queryUnwantedKeys) {
      setValue("unwantedKeys", queryUnwantedKeys.map((value) => ({
        label: unwantedKeysOptions.find((option) => option.value === value).label,
        value
      })));
    }
    if (queryProjectId) {
      setValue("projectId", Number(queryProjectId));
    }
  }, []);

  const handleRemoveUnwantedKey = (value) => () => {
    const newUnwantedKeys = unwantedKeys.filter(({ value: innerValue }) => innerValue !== value);

    setValue("unwantedKeys", newUnwantedKeys);
  };

  return (
    <Page
      className=""
      title="Käufer-Differenzen"
    >
      <PageHeader>
        <PageTitle>Käufer-Differenzen</PageTitle>

        <div className="flex justify-between">
          <div className="mt-4 flex flex-col gap-2">
            <NumberInput
              int
              allowNegative={false}
              name="projectId"
              placeholder="Projekt-ID"
              thousandSeparator=""
              {...{
                control,
                errors,
                register
              }}

            />

            <Select
              multiple
              className="w-64"
              defaultValue={[]}
              label="Unerwünschte Felder"
              name="unwantedKeys"
              options={unwantedKeysOptions}
              {...{ control }}
            />

            <ul className="flex w-96 flex-wrap gap-1">
              {
                unwantedKeys
                  .toSorted(({ value: valueA }, { value: valueB }) => {
                    unwantedKeysOptions.findIndex(({ value }) => value === valueA) - unwantedKeysOptions.findIndex(({ value }) => value === valueB);
                  })
                  .map(({ label, value }) => (
                    <li
                      className="flex cursor-pointer items-center gap-1 rounded-sm bg-gray-200 px-1.5 py-1 text-xs hover:bg-gray-300"
                      key={value}
                      onClick={handleRemoveUnwantedKey(value)}
                    >
                      <span>{label}</span>

                      <XIcon className="size-3" />
                    </li>
                  ))
              }
            </ul>
          </div>

          <span className="relative mt-8 flex h-24 w-80 flex-col justify-between rounded-sm bg-gray-200 px-3 py-2 text-sm text-gray-700">
            {
              pagination
                ? (
                  <>
                    <span className="font-medium">Auf dieser Seite:</span>

                    <span>{pagination.totalDifferences} {pluralize("Differenz", "Differenzen", pagination.totalDifferences)}</span>

                    <span>in {pagination.totalUnits} {pluralize("Objekt", "Objekten", pagination.totalUnits)}</span>

                    <span>in {pagination.totalProjects} {pluralize("Projekt", "Projekten", pagination.totalProjects)} gefunden</span>
                  </>
                )
                : null
            }

            {
              isLoading
                ? (
                  <div className="absolute right-4 top-4 flex flex-col items-end gap-1">
                    <DownloadIcon
                      className="size-5 animate-bounce"
                    />
                  </div>
                )
                : null
            }

          </span>
        </div>

      </PageHeader>

      <MainContainer>
        <Pagination
          className="mt-8"
          {...{
            labels: ["Projekt", "Projekte"],
            page: pageNumber,
            pagination,
            setPage: setPageNumber
          }}
        />

        <div className="flex w-full flex-col items-center">
          <UnitBuyerDifferencesList
            {...{
              editPossible: !isLoading,
              isLoading,
              mutate,
              unitBuyerDifferences
            }}
          />
        </div>
      </MainContainer>
    </Page>
  );
};

export default UnitBuyerDifferencesPage;
