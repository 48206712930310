import cn from "classnames";
import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import ProjectInfosTotalArea from "~/src/ui/displays/project-infos-total-area/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import EditQuantitySlide from "./edit-quantity-slide.jsx";
import { handleDelete } from "./handlers.js";
import NewQuantitySlide from "./new-quantity-slide.jsx";

/**
 *
 * @example
 */
const ProjectQuantities = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);

  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    hide: hideNewSlide, show: showNewSlide, visible: newSlideVisible
  } = useModal();
  const {
    data: editSlideData, hide: hideEditSlide, show: showEditSlide, visible: editSlideVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  return (
    <TabListContainer>
      <NewQuantitySlide hide={hideNewSlide} projectId={id} visible={newSlideVisible} />

      <EditQuantitySlide hide={hideEditSlide} projectId={id} quantity={editSlideData} visible={editSlideVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Einheiten" />

        <DescriptionContainer>
          {project?.quantities.map((quantity, index) => (

            <div
              key={`quantity_${quantity.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Objektkategorie</p>

                <p className="">{quantity.category.name}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Einheiten</p>

                <p className="">{quantity.total_units ? quantity.total_units : "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Fläche</p>

                <ProjectInfosTotalArea area={quantity.total_area_range_from} toArea={quantity.total_area_range_to} />
              </div>

              {showEditActions
                ? (
                  <div className="col-span-1 flex items-center justify-end">
                    <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showEditSlide(quantity)} type="button">
                      <EditIcon className="size-6" />
                    </button>

                    <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showDelete(quantity)} type="button">
                      <DeleteIcon className="size-6" />
                    </button>
                  </div>
                )
                : null}

            </div>
          ))}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showNewSlide()}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectQuantities;
