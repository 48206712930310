import { mapValues } from "radashi";

/**
 * @template T
 * @typedef {T extends null? undefined: T} ReplaceNullWithUndefined
 */

/**
 * @template T
 * @typedef {{[P in keyof T]: ReplaceNullWithUndefined<T[P]>}} ReplaceNullWithUndefinedProperties
 */

/**
 * Replaces all `null` values in an object with `undefined`.
 *
 * @template {object} T
 * @param {T} object
 * @returns {ReplaceNullWithUndefinedProperties<T>}
 * @example
 * const object = {
 *  id: 1,
 * name: null,
 * };
 *
 * const result = replaceNullWithUndefinedProperties(object);
 *
 * console.log(result);
 * // Output: { id: 1, name: undefined }
 */
const replaceNullWithUndefinedProperties = (object) => (
  /** @type {ReplaceNullWithUndefinedProperties<T>} */ (
    mapValues(
      object,
      (value) => value ?? undefined
    )
  )
);

export default replaceNullWithUndefinedProperties;
