import { useEffect } from "react";

import {
  constructionPhaseFilterLabels, marketingPhaseFilterLabels, researchFilterOptionLabels, sortFilterLabels, statusFilterLabels, synchronizedFilterLabels
} from "~/src/modules/labels.jsx";

import NewProjectModal from "~/src/features/modals/new-project-modal/index.jsx";
import Pagination from "~/src/features/pagination/index.jsx";
import ProjectList from "~/src/features/project-list/index.jsx";
import ProjectsSearchForm from "~/src/features/projects-search-form/index.jsx";

import useActiveUserProjects from "~/src/hooks/use-active-user-projects.js";
import useModal from "~/src/hooks/use-modal.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useProjects from "~/src/hooks/use-projects.js";
import useStore from "~/src/hooks/use-store.js";

import ActiveProjectLink from "~/src/ui/active-project-link/index.jsx";
import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";
import FilterEvent from "~/src/ui/search/filter-event/index.jsx";

/**
 *
 * @example
 */
const ProjectsPage = () => {
  const {
    hide: hideNewModal, show: showNewModal, visible: newModalVisible
  } = useModal();
  const { query, setQuery } = useProjectQueryParams();
  const setSavedQuery = useStore((state) => state.setSavedQuery);

  const {
    cityId, constructionPhaseFilter, marketingPhaseFilter, name, page, researchFilter, sortFilter, state, statusFilter, street, synchronizedFilter
  } = query;
  const {
    isError, isLoading, pagination, projects
  } = useProjects(query);
  const { projects: activeUserProjects } = useActiveUserProjects();

  useEffect(() => {
    setSavedQuery(query);
  }, [query]);

  const handlePageChange = (value) => {
    setQuery({ page: value });
  };

  const resetFilter = () => {
    setQuery({}, "replace");
  };

  const handleValueUpdate = (filter, value) => {
    switch (filter) {
      case "cityIdChange":
        if (value) {
          setQuery({ cityId: value.id }); //query.zipcode - value is object with zipcode + name
        }
        else {
          setQuery({ cityId: undefined });
        }
        break;

      case "constructionPhaseQueryChange":
        setQuery({ constructionPhaseFilter: value }); //query.constructionPhaseFilter
        break;

      case "marketingPhaseQueryChange":
        setQuery({ marketingPhaseFilter: value }); //query.marketingPhaseFilter
        break;

      case "nameChange":
        if (value) {
          setQuery({ name: value });
        }
        else {
          setQuery({ name: undefined });
        }
        break;

      case "researchChange":
        setQuery({ researchFilter: value }); //query.researchFilter
        break;

      case "sortChange":
        setQuery({ sortFilter: value }); //query.sortFilter
        break;

      case "stateChange":
        if (value) {
          setQuery({ state: value }); //query.state
        }
        else {
          setQuery({ state: undefined }); //query.state
        }
        break;

      case "statusChange":
        setQuery({ statusFilter: value }); //query.statusFilter
        break;

      case "streetChange":
        if (value) {
          setQuery({ street: value });
        }
        else {
          setQuery({ street: undefined });
        }
        break;

      case "synchronizeChange":
        setQuery({ synchronizedFilter: value }); //query.synchronizedFilter
        break;

      default:
        break;
    }
  };

  if (isError) {
    return null;
  }

  return (
    <Page title="Übersicht Projekte">

      <NewProjectModal hide={hideNewModal} show={newModalVisible} />

      <PageHeader>
        <div className="justify-between sm:flex">
          <div>
            <h2 className="text-2xl font-bold leading-7 text-gray-800 sm:truncate sm:text-3xl">
              Projektliste
            </h2>

            <button className="mt-6 block text-sm underline focus:outline-hidden" onClick={showNewModal} type="button">Neues Projekt hinzufügen</button>
          </div>

          <div className="mt-12 sm:mt-0 sm:text-right">
            {activeUserProjects?.length > 0 &&
              <h3 className="mb-2 text-sm font-medium text-gray-700">Meine aktiven Projekte</h3>}

            {activeUserProjects?.map((project) => (
              <div className="mb-3" key={`active_projects_${project.id}`}>
                <ActiveProjectLink project={project} />
              </div>
            ))}
          </div>
        </div>
      </PageHeader>

      <MainContainer>

        <ProjectsSearchForm cityId={cityId} handleUpdate={handleValueUpdate} name={name} resetFilter={resetFilter} state={state} street={street} />

        <div className="mt-12 flex max-w-full flex-col gap-4 md:flex-row">
          <FilterEvent
            eventName="researchChange"
            filter={researchFilter}
            handleUpdate={handleValueUpdate}
            label="Erfassungsstatus"
            options={researchFilterOptionLabels}
          />

          <FilterEvent
            eventName="constructionPhaseQueryChange"
            filter={constructionPhaseFilter}
            handleUpdate={handleValueUpdate}
            label="Baustatus"
            options={constructionPhaseFilterLabels}
          />

          <FilterEvent
            eventName="marketingPhaseQueryChange"
            filter={marketingPhaseFilter}
            handleUpdate={handleValueUpdate}
            label="Vermarktungsstatus"
            options={marketingPhaseFilterLabels}
          />

          <FilterEvent
            eventName="sortChange"
            filter={sortFilter}
            handleUpdate={handleValueUpdate}
            label="Sortieren nach"
            options={sortFilterLabels.nonHistoric}
          />

          <FilterEvent
            eventName="statusChange"
            filter={statusFilter}
            handleUpdate={handleValueUpdate}
            label="Anzeigestatus"
            options={statusFilterLabels}
          />

          <FilterEvent
            eventName="synchronizeChange"
            filter={synchronizedFilter}
            handleUpdate={handleValueUpdate}
            label="Synchronisert"
            options={synchronizedFilterLabels}
          />
        </div>

        <div className="mt-4">
          <Pagination
            labels={["Projekt", "Projekte"]}
            page={page}
            pagination={pagination}
            setPage={handlePageChange}
          />
        </div>

        <ProjectList isLoading={isLoading} projects={projects} />

        <Pagination
          labels={["Projekt", "Projekte"]}
          page={page}
          pagination={pagination}
          setPage={handlePageChange}
        />

      </MainContainer>

    </Page>
  );
};

export default ProjectsPage;
