import Joi from "joi";

const initialValues = {
  research_notes: ""
};

const schema = Joi.object({
  research_notes: Joi.string().optional()
});

export { initialValues };

export default schema;
