import { useState } from "react";
import { useParams } from "react-router-dom";

import DeleteProjectModal from "~/src/features/project-settings/delete-project-modal/index.jsx";
import ProjectSettings from "~/src/features/project-settings/index.jsx";

import useProject from "~/src/hooks/use-project.js";

import TabHeader from "~/src/ui/headers/tab-header/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const ProejctSettingsPage = () => {
  const { id } = useParams();
  const {
    isError, isLoading, project
  } = useProject(id);
  const [showModal, setShowModal] = useState(false);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return <Skeleton />;
  }

  return (
    <div>
      <DeleteProjectModal hide={() => setShowModal(false)} project={project} show={showModal} />

      <TabHeader>Einstellungen</TabHeader>

      <div>
        <ProjectSettings />
      </div>

    </div>
  );
};

export default ProejctSettingsPage;
