import cn from "classnames";

import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.error - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.name - The root object
 * @param props0.onAction - The root object
 * @param props0.onBlur - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.showAction - The root object
 * @param props0.touched - The root object
 * @param props0.type - The root object
 * @param props0.value - The root object
 * @example
 */
const InlineActionInput = ({
  error,
  isSubmitting,
  name,
  onAction,
  onBlur,
  onChange,
  placeholder,
  showAction = true,
  touched,
  type = "search",
  value
}) => {
  const touchedAndEmpty = (Boolean(touched) === true) && value === "";
  const showError = error && (isSubmitting || touchedAndEmpty);

  return (
    <>
      <div className="flex">
        <div className="relative z-10 flex grow items-stretch">
          <input
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            className={cn("block w-full border rounded-xs shadow-xs bg-white h-10 px-3 focus:outline-hidden sm:text-sm placeholder-gray-300", {
              "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
              "border-red-500 focus:ring-red-700 focus:border-red-700": showError
            })}
          />

          {showError
            ? (
              <div
                className={cn(
                  "absolute inset-y-0 right-0 flex items-center pointer-events-none",
                  {
                    "pr-3": type !== "number",
                    "pr-8": type === "number"
                  }
                )}
              >
                <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                  <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
                </svg>
              </div>
            )
            : null}
        </div>

        {showAction
          ? (
            <button className="relative -ml-px inline-flex items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-hidden" onClick={onAction} type="button">
              <DeleteIcon className="size-5 text-gray-400" />
            </button>
          )
          : null}

      </div>

      {showError ? <p className="mt-2 text-sm text-red-600">{error.message}</p> : null}
    </>
  );
};

export default InlineActionInput;
