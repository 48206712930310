import Joi from "joi";

/**
 * @import { ObjectSchema } from "joi";
 */

/**
 * Validation schema for project certificate form
 *
 * @type {ObjectSchema}
 */
const schema = Joi.object({
  assigned_points: Joi.any()
    .when(
      "issuer.has_points",
      {
        otherwise: Joi.any(),
        then: Joi.number().max(Joi.ref("issuer.max_points")).min(1)
          .empty("")
          .allow(null)
      }
    ),
  issuer: Joi.object({
    id: Joi.number().positive().empty("")
  })
    .unknown()
    .required()
    .empty(null),
  issuer_certificate: Joi.object({
    id: Joi.number().positive().empty(""),
    issuer_id: Joi.allow(Joi.ref("issuer.id")).empty("")
  })
    .unknown()
    .empty(null),
  notes: Joi.string().empty("").allow(null)
    .max(500)
})
  .unknown();

export default schema;
