/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import cn from "classnames";
import { camelCase, snakeCase } from "lodash-es";
import {
  EyeIcon, EyeOffIcon, Grid2x2PlusIcon
  , SquarePenIcon, Trash2Icon
} from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { formatArea } from "~/src/modules/formatters.js";
import { sortFilters } from "~/src/modules/labels.jsx";

import DeleteBauteilModal from "~/src/features/modals/delete-bauteil-modal/index.jsx";
import EditBauteilModal from "~/src/features/modals/edit-bauteil-modal/index.jsx";
import NewBauteilModal from "~/src/features/modals/new-bauteil-modal/index.jsx";
import Pagination from "~/src/features/pagination/index.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";
import useStore from "~/src/hooks/use-store.js";
import useUnits from "~/src/hooks/use-units.js";

import ScrollTopButton from "~/src/ui/buttons/scroll-top-button/index.jsx";
import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import BulkEditCheckbox from "~/src/ui/forms/bulk-edit-checkbox/index.jsx";
import BulkEditIcon from "~/src/ui/icons/bulk-edit-icon.jsx";
import CloseIcon from "~/src/ui/icons/close-icon.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import PlusTripleIcon from "~/src/ui/icons/plus-triple-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import ProjectUnit from "~/src/ui/project-unit/index.jsx";
import ProjectUnitCompact from "~/src/ui/project-unit-compact/index.jsx";
import Filter from "~/src/ui/search/filter/index.jsx";

import { handleDelete as handleDocumentDelete } from "./document-handlers.js";
import EditUnitDocumentSlide from "./edit-unit-document-slide.jsx";
import { handleDelete } from "./handlers.js";
import { getDefaultFieldValue, getDifferentKeysFromKeyObject } from "./helpers.js";
import { initialValuesEdit } from "./index/_exports.js";
import NewUnitDocumentsSlide from "./new-unit-documents-slide.jsx";
import SearchUnits from "./search-units.jsx";
import UnitFormSlide from "./unit-form-slide.jsx";
import UnitFormSlideBulk from "./unit-form-slide-bulk.jsx";
import UnitFormSlideBulkEdit from "./unit-form-slide-bulk-edit.jsx";

const ProjectUnits = () => {
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [tz, setTz] = useState("");
  const [sortFilter, setSortFilter] = useState("updated-at-desc");
  const [showHidden, setShowHidden] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentBuildingPart, setCurrentBuildingPart] = useState("");
  const [presetKeys, setPresetKeys] = useState([]);
  const [formValues, setFormValues] = useState(initialValuesEdit);

  const bulkEditSelection = useStore((state) => state.bulkEditSelection);
  const setBulkEditSelection = useStore((state) => state.setBulkEditSelection);
  const selectedBulkUnits = useStore((state) => state.selectedBulkUnits);
  const setSelectedBulkUnits = useStore((state) => state.setSelectedBulkUnits);
  const viewFilter = useStore((state) => state.viewFilter);
  const setViewFilterCompact = useStore((state) => state.setViewFilterCompact);
  const setViewFilterDetail = useStore((state) => state.setViewFilterDetail);

  const { id } = useParams();
  const { isMod, user } = useAuth();
  const {
    isError, isLoading, mutate: mutateProject, project
  } = useProject(id);
  const {
    categories, mutate: mutateUnits, pagination, unitStats, units
  } = useUnits({
    bauteil: currentBuildingPart,
    category: currentCategory,
    hidden: showHidden,
    name,
    page,
    projectId: id,
    sort: sortFilter,
    tz,
    view: viewFilter
  });
  const { editPossible, editUnitPossible } = useEdit({
    project,
    user
  });

  const showEditActions = editPossible;
  const showEditUnitActions = editUnitPossible;
  const buildingParts = [
    {
      label: "-",
      value: ""
    }
  ].concat(project?.building_parts.map((bp) => ({
    label: bp.name,
    value: bp.id
  })));
  const visibilityKey = showHidden ? "hidden" : "shown";

  const {
    bauteilVisible,
    hideBauteil,
    showBauteil,

    editBauteilData,
    editBauteilVisible,
    hideEditBauteil,
    showEditBauteil,

    deleteBauteilData,
    deleteBauteilVisible,
    hideDeleteBauteil,
    showDeleteBauteil,

    hideSlide,
    showSlide,
    slideData,
    slideVisible,

    bulkSlideData,
    bulkSlideVisible,
    hideBulkSlide,
    showBulkSlide,

    bulkEditSlideData,
    bulkEditSlideVisible,
    hideBulkEditSlide,
    showBulkEditSlide,

    documentSlideData,
    documentSlideVisible,
    hideDocumentSlide,
    showDocumentSlide,

    editDocumentData,
    editDocumentVisible,
    hideEditDocument,
    showEditDocument,

    deleteData,
    deleteVisible,
    hideDelete,
    showDelete,

    deleteDocumentData,
    deleteDocumentVisible,
    hideDeleteDocument,
    showDeleteDocument
  } = Object.fromEntries(
    [
      "bauteil",
      "editBauteil",
      "deleteBauteil",
      "slide",
      "bulkSlide",
      "bulkEditSlide",
      "documentSlide",
      "editDocument",
      "delete",
      "deleteDocument"
    ]
      .flatMap((key) => {
        const {
          data, hide, show, visible
        } = useModal();

        return [
          [camelCase([key, "data"].join("-")), data],
          [camelCase(["hide", key].join("-")), hide],
          [camelCase(["show", key].join("-")), show],
          [camelCase([key, "visible"].join("-")), visible]
        ];
      })
  );

  const getBulkEditUnits = () => selectedBulkUnits;

  const unitIsSetBulkEdit = (id) => bulkEditSelection.includes(id.toString());

  const removeBulkEditSelection = () => {
    setBulkEditSelection([]);
    setSelectedBulkUnits([]);
  };

  const toggleHidden = (value) => {
    setShowHidden(value);
    setPage(1);
  };

  const editBulkEdit = (event) => {
    if (bulkEditSelection.includes(event.target.id)) {
      const index = bulkEditSelection.indexOf(event.target.id);

      setBulkEditSelection([...bulkEditSelection.slice(0, index), ...bulkEditSelection.slice(index + 1)]);
    }
    else {
      setBulkEditSelection([...bulkEditSelection, event.target.id]);
    }
  };

  const bulkEditUnits = (ids) => {
    const oldBulkEditElements = (selectedBulkUnits && selectedBulkUnits.length > 0)
      ? selectedBulkUnits.map((unit) => {
        if (ids.includes(unit.id.toString())) {
          return unit;
        }
      }).filter((unit) => unit !== undefined)
      : [];

    const newBulkEditElements = units.map((unit) => {
      if (ids.includes(unit.id.toString())) {
        return unit;
      }
    }).filter((unit) => unit !== undefined);

    const combinedEditElements = [...oldBulkEditElements, ...newBulkEditElements];

    const uniqueEditElements = combinedEditElements.reduce((uniqueEditElements, editElements) => {
      if (!uniqueEditElements.has(editElements.id)) {
        uniqueEditElements.set(editElements.id, editElements);
      }

      return uniqueEditElements;
    }, new Map());

    setSelectedBulkUnits([...uniqueEditElements.values()]);
  };

  useEffect(() => {
    removeBulkEditSelection();
  }, [currentCategory, currentBuildingPart]);

  useEffect(() => {
    mutateUnits();
  }, [currentCategory, mutateUnits]);

  useEffect(() => {
    if (bulkEditSelection && bulkEditSelection.length > 0) {
      bulkEditUnits(bulkEditSelection);
    }
  }, [bulkEditSelection]);

  useEffect(() => {
    if (selectedBulkUnits && selectedBulkUnits.length > 0) {
      const keyObject = (selectedBulkUnits)
        ? Object.fromEntries(
          Object.keys(initialValuesEdit)
            .map((key) => [key, selectedBulkUnits.map((object) => object[snakeCase(key)])])
        )
        : {};

      const diffKeys = (keyObject)
        ? Object.keys(keyObject)
          .filter((key) => getDifferentKeysFromKeyObject(key, keyObject[key]))
          .map((key) => key)
        : [];

      setPresetKeys(diffKeys);

      const updatedFormValues = Object.fromEntries(
        Object.keys(initialValuesEdit)
          .map((key) => [key, getDefaultFieldValue(key, diffKeys, initialValuesEdit, keyObject)])
      );

      setFormValues(updatedFormValues);
    }
  }, [selectedBulkUnits]);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <div>
      <UnitFormSlide buildingParts={project?.building_parts} hide={hideSlide} mutateUnits={mutateUnits} projectId={id} unit={slideData} visible={slideVisible} />

      <UnitFormSlideBulk buildingParts={project?.building_parts} hide={hideBulkSlide} mutateUnits={mutateUnits} projectId={id} units={bulkSlideData} visible={bulkSlideVisible} />

      <UnitFormSlideBulkEdit formValuesUnit={formValues} hide={hideBulkEditSlide} mutateUnits={mutateUnits} presetKeys={presetKeys} projectIds={bulkEditSelection} resetSelection={removeBulkEditSelection} setPage={setPage} units={bulkEditSlideData} visible={bulkEditSlideVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete: () => {
            handleDelete({
              id: deleteData?.id,
              handleClose: hideDelete,
              mutate: mutateUnits
            });
          },
          hide: hideDelete,
          mutate: mutateUnits,
          show: deleteVisible
        }}
      />

      <NewUnitDocumentsSlide data={documentSlideData} hide={hideDocumentSlide} mutateUnits={mutateUnits} projectId={id} visible={documentSlideVisible} />

      <EditUnitDocumentSlide document={editDocumentData} hide={hideEditDocument} mutateUnits={mutateUnits} projectId={id} visible={editDocumentVisible} />

      <DeleteModal
        {...{
          id: deleteDocumentData?.id,
          handleDelete: handleDocumentDelete,
          hide: hideDeleteDocument,
          mutate: mutateUnits,
          show: deleteDocumentVisible
        }}
      />

      <NewBauteilModal hide={hideBauteil} mutateProject={mutateProject} projectId={project?.id} visible={bauteilVisible} />

      <EditBauteilModal hide={hideEditBauteil} mutateProject={mutateProject} part={editBauteilData} visible={editBauteilVisible} />

      <DeleteBauteilModal hide={hideDeleteBauteil} mutateProject={mutateProject} part={deleteBauteilData} visible={deleteBauteilVisible} />

      <div className="min-h-180 grid grid-cols-8 gap-4">
        <div className="col-span-5 flex flex-col gap-4 bg-gray-100 p-4 shadow-sm">
          <div className="flex w-full justify-between gap-4">
            <h2 className="text-xl font-medium text-gray-800">Objekte</h2>

            {
              unitStats
                ? (
                  <div className="flex items-end gap-4">
                    <span>Gesamtanzahl: </span>

                    <div className="flex items-center gap-4">
                      <span className="col-span-1 flex justify-end">{unitStats.count.total}</span>

                      <button
                        onClick={() => toggleHidden(!showHidden)}
                        className={cn("text-sm flex items-center px-2 bg-gray-300 rounded-sm focus:outline-hidden ", {
                          "bg-yellow-500 text-white": !showHidden
                        })}
                      >
                        <EyeIcon className="size-3.5 " />

                        <span className="ml-1.5">{unitStats.count.shown}</span>
                      </button>

                      <button
                        onClick={() => toggleHidden(!showHidden)}
                        className={cn("text-sm flex items-center px-2 bg-gray-300 rounded-sm focus:outline-hidden", {
                          "bg-yellow-500 text-white": showHidden
                        })}
                      >
                        <EyeOffIcon className="size-3.5 " />

                        <span className="ml-1.5">{unitStats.count.hidden}</span>
                      </button>

                    </div>

                  </div>
                )
                : null
            }
          </div>

          <ul className="grid grid-cols-4 gap-4">

            {unitStats
              ? Object.keys(unitStats).filter((key) => key !== "count").map((cat, index) => {
                const stat = unitStats[cat];

                return (
                  <li className="col-span-4 grid grid-cols-12 gap-4" key={`area_category_${index}`}>
                    <span className="col-span-3">{stat.name}:</span>

                    <span className="col-span-1 flex justify-end self-start">{stat.count.total}</span>

                    <div className="col-span-8 -mt-1.5 grid grid-cols-8 rounded-sm bg-white/50 p-2 text-sm">
                      <span className="col-span-2">Angebot:</span>

                      <span className="col-span-6">{stat.offerArea ? `${formatArea(stat.offerArea[visibilityKey])} (${stat.offerAreaCount[visibilityKey]})` : "-"}</span>

                      <span className="col-span-2">Verkauf:</span>

                      <span className="col-span-6">{stat.saleArea ? `${formatArea(stat.saleArea[visibilityKey])} (${stat.saleAreaCount[visibilityKey]})` : "-"}</span>
                    </div>
                  </li>
                );
              })
              : null}
          </ul>
        </div>

        <div className="col-span-1" />

        <div className="col-span-2 bg-gray-100 p-4 shadow-sm">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-xl font-medium text-gray-800">Bauteile</h2>

            {showEditActions
              ? (
                <button
                  className="focus:outline-hidden"
                  type="button"
                  onClick={() => {
                    showBauteil(project);
                  }}
                >
                  <Grid2x2PlusIcon className="ml-1 size-6 hover:text-gray-500" />
                </button>
              )
              : null}
          </div>

          <ul>
            {project?.building_parts.map((part, index) => (
              <div className="grid grid-cols-5 space-x-2 text-sm" key={`building_part_${index}`}>
                <p className="col-span-4 truncate">
                  <span className="inline-block w-8">{Number.parseFloat(part.sorting)}:</span>

                  <span title={part.name}>
                    {part.name}
                  </span>
                </p>

                <div className="flex items-center">
                  {showEditActions
                    ? (
                      <>
                        <button
                          className="focus:outline-hidden"
                          type="button"
                          onClick={() => {
                            showEditBauteil(part);
                          }}
                        >
                          <SquarePenIcon className="ml-1 size-4 hover:text-gray-500" />
                        </button>

                        <button
                          className="focus:outline-hidden"
                          type="button"
                          onClick={() => {
                            showDeleteBauteil(part);
                          }}
                        >
                          <Trash2Icon className="ml-1 size-4 hover:text-gray-500" />
                        </button>
                      </>
                    )
                    : null}
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <SearchUnits setName={setName} setPage={setPage} setTz={setTz} />

      <div className="sticky top-12 z-40 mt-8 border-b-2 border-amber-300 bg-amber-100 p-4 backdrop-blur-md">
        <div className="flex items-end justify-between">
          <div className="flex space-x-4">
            <Filter
              filter={sortFilter}
              label="Sortieren nach"
              options={sortFilters}
              setFilter={setSortFilter}
              setPage={setPage}
            />

            <Filter
              filter={currentCategory}
              label="Kategorie"
              setFilter={setCurrentCategory}
              setPage={setPage}
              options={categories?.map((uc) => ({
                label: uc.name,
                value: uc.code
              })) || []}
            />

            <Filter
              filter={currentBuildingPart}
              label="Bauteil"
              options={buildingParts}
              setFilter={setCurrentBuildingPart}
              setPage={setPage}
            />
          </div>

          <div className="ml-8 space-x-2 text-sm text-gray-800">
            <span>Ansicht:</span>

            <button
              onClick={setViewFilterCompact}
              type="button"
              className={cn("focus:outline-hidden", {
                "font-bold": viewFilter === "compact",
                underline: viewFilter !== "compact"
              })}
            >
              Kompakt
            </button>

            <button
              onClick={setViewFilterDetail}
              type="button"
              className={cn("focus:outline-hidden", {
                "font-bold": viewFilter !== "compact",
                underline: viewFilter === "compact"
              })}
            >
              Detail
            </button>
          </div>
        </div>

      </div>

      <div className="mt-4">
        <Pagination labels={["Objekt", "Objekte"]} page={page} pagination={pagination} setPage={setPage} />
      </div>

      <div
        className={cn("flex flex-col", {
          "gap-y-6": viewFilter === "compact",
          "gap-y-16": viewFilter !== "compact"
        })}
      >

        {units?.map((unit) => {
          if (unit.deleted_at) {
            return (
              <div className="flex items-center justify-between rounded-sm p-4" key={`unit_${unit.id}`}>
                {showEditUnitActions
                  ? (
                    <div className="flex items-center justify-end">
                      <button
                        className="focus:outline-hidden inline-flex items-center text-gray-500 hover:text-gray-400"
                        type="button"
                        onClick={() => {
                          removeBulkEditSelection();
                          showSlide(unit);
                        }}
                      >
                        <EditIcon className="size-6" />
                      </button>
                    </div>
                  )
                  : null}
              </div>
            );
          }

          if (viewFilter === "compact") {
            return (
              <div className="relative pt-6" key={`unit_${unit.id}`}>
                <div
                  className={cn("", {
                    "flex justify-between items-center": !unit.show
                  })}
                >
                  {!unit.show && (
                    <p className="inline-flex items-center rounded-sm bg-amber-500 px-2 py-0.5 text-xs text-white">
                      <EyeOffIcon className="size-3.5" />

                      <span className="ml-2">wird im Produkt nicht anzeigt</span>
                    </p>
                  )}

                  {showEditUnitActions
                    ? (
                      <div className="absolute -right-8 top-8 flex flex-col items-center justify-start gap-3">
                        <button
                          className="focus:outline-hidden inline-flex items-center justify-center text-gray-500 hover:text-gray-400"
                          type="button"
                          onClick={() => {
                            removeBulkEditSelection();
                            showSlide(unit);
                          }}
                        >
                          <EditIcon className="size-6" />
                        </button>

                        <button
                          className="focus:outline-hidden inline-flex items-center justify-center text-gray-500 hover:text-gray-400"
                          type="button"
                          onClick={() => {
                            removeBulkEditSelection();
                            showDelete(unit);
                          }}
                        >
                          <DeleteIcon className="size-6" />
                        </button>

                        {isMod
                          ? (
                            <BulkEditCheckbox
                              id={unit.id}
                              name={`bulkEditCheckbox_${unit.id}`}
                              onChange={editBulkEdit}
                              value={unitIsSetBulkEdit(unit.id)}
                            />
                          )
                          : null}
                      </div>
                    )
                    : null}
                </div>

                <ProjectUnitCompact
                  isDummy={false}
                  showEditActions={showEditUnitActions}
                  showSlide={showDocumentSlide}
                  unit={unit}
                />
              </div>
            );
          }

          return (
            <div className="pt-6" key={`unit_${unit.id}`}>
              <div
                className={cn("", {
                  "flex justify-between items-center": !unit.show
                })}
              >
                {!unit.show && (
                  <p className="inline-flex items-center rounded-sm bg-amber-500 px-2 py-0.5 text-xs text-white">
                    <EyeOffIcon className="size-3.5" />

                    <span className="ml-2">wird im Produkt nicht anzeigt</span>
                  </p>
                )}

                {showEditUnitActions
                  ? (
                    <div className="flex items-center justify-end gap-3">
                      <button
                        className="focus:outline-hidden inline-flex items-center text-gray-500 hover:text-gray-400"
                        type="button"
                        onClick={() => {
                          removeBulkEditSelection();
                          showSlide(unit);
                        }}
                      >
                        <EditIcon className="size-6" />
                      </button>

                      <button
                        className="focus:outline-hidden inline-flex items-center text-gray-500 hover:text-gray-400"
                        type="button"
                        onClick={() => {
                          removeBulkEditSelection();
                          showDelete(unit);
                        }}
                      >
                        <DeleteIcon className="size-6" />
                      </button>

                      {isMod
                        ? (
                          <BulkEditCheckbox
                            id={unit.id}
                            name={`bulkEditCheckbox_${unit.id}`}
                            onChange={editBulkEdit}
                            value={unitIsSetBulkEdit(unit.id)}
                          />
                        )
                        : null}
                    </div>
                  )
                  : null}
              </div>

              <ProjectUnit
                showDeleteDocument={showDeleteDocument}
                showEditActions={showEditUnitActions}
                showEditDocument={showEditDocument}
                showSlide={showDocumentSlide}
                unit={unit}
              />
            </div>
          );
        })}
      </div >

      <Pagination labels={["Objekt", "Objekte"]} page={page} pagination={pagination} setPage={setPage} />

      <div className="fixed bottom-4 right-4">
        <div className="space-x-2">
          {(bulkEditSelection.length > 0 && isMod)
            ? (
              <>
                <SubmitButton className="shadow-xs focus:outline-hidden inline-flex items-center rounded-sm border border-transparent bg-red-700 px-4 py-2 text-base font-medium text-white hover:bg-red-800 focus:ring-2 focus:ring-red-700 focus:ring-offset-2" disabled={!(project?.quantities.length)} onClick={() => removeBulkEditSelection()}>
                  <CloseIcon size="h-5 w-5" />
                </SubmitButton>

                <SubmitButton disabled={!((project?.quantities.length && selectedBulkUnits?.length > 1))} onClick={() => showBulkEditSlide(getBulkEditUnits())}>
                  <BulkEditIcon className="size-5" />
                </SubmitButton>
              </>
            )
            : null}

          {(showEditUnitActions && isMod)
            ? (
              <SubmitButton
                disabled={!(project?.quantities.length)}
                onClick={() => {
                  removeBulkEditSelection();
                  showBulkSlide();
                }}
              >
                <PlusTripleIcon className="size-5" />
              </SubmitButton>
            )
            : null}

          {showEditUnitActions
            ? (
              <SubmitButton
                disabled={!(project?.quantities.length)}
                onClick={() => {
                  removeBulkEditSelection();
                  showSlide();
                }}
              >
                <PlusIcon className="size-5" />
              </SubmitButton>
            )
            : null}

          <ScrollTopButton />
        </div>
      </div>

    </div >
  );
};

export default ProjectUnits;
