/**
 *
 * @param props0 - The root object
 * @param props0.disabled - The root object
 * @param props0.onClick - The root object
 * @example
 */
const CancelButton = ({ disabled = false, onClick }) => (
  <button
    className="rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-xs hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 disabled:opacity-50"
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    Abbrechen
  </button>
);

export default CancelButton;
