import { SearchIcon } from "lucide-react";
import { useForm } from "react-hook-form";

import Field from "~/src/ui/forms/field/index.jsx";

const CertificateIssuerSearchForm = ({ name, setName }) => {
  const {
    formState: {
      errors
    },
    handleSubmit,
    register
  } = useForm({
    defaultValues: {
      name
    }
  });

  const onSubmit = ({ name: currentName }) => {
    setName(currentName);
  };

  return (
    <form className="flex flex-col" onChange={handleSubmit(onSubmit)} onSubmit={handleSubmit(onSubmit)} >
      <Field
        inline
        className="w-64"
        icon={SearchIcon}
        label="Ausstellername"
        name="name"
        {...{
          errors,
          register
        }}
      />
    </form>

  );
};

export default CertificateIssuerSearchForm;
