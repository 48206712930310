import { PlusIcon } from "lucide-react";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import CloseIcon from "~/src/ui/icons/close-icon.jsx";
import CompanySelect from "~/src/ui/selects/company-select/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleCreate } from "./handlers.js";
import {
  initialValues, roles, schema
} from "./schema.js";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.projectId - The root object
 * @param props0.roleName - The root object
 * @param props0.showNewCompanyModal - The root object
 * @param props0.visible - The root object
 * @example
 */
const NewCompanyRoleSlide = ({
  hide, projectId, roleName, showNewCompanyModal, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  /**
   *
   * @param innerForm
   * @example
   */
  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleCreate({
        data: form.values,
        projectId,
        role: roleName,

        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={(e) => e.preventDefault()}>
        <div className="flex-1">

          <div className="bg-gray-800 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2 className="text-lg font-medium text-white">
                  Neuer {roles[roleName]}
                </h2>
              </div>

              <div className="flex h-7 items-center">
                <button
                  className="rounded-md text-white hover:text-gray-100 focus:outline-hidden"
                  type="button"
                  onClick={() => {
                    handleHide(form);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="private">
                  Privat
                </label>
              </div>

              <div className="sm:col-span-2">
                <InlineCheckbox
                  error={form.errors.private}
                  id="private"
                  isSubmitting={form.isSubmitting}
                  label=""
                  name="private"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.private}
                  value={form.values.private}
                />
              </div>
            </div>

            {
              !form.values.private && (
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="company">
                      Unternehmen
                    </label>

                    <div className="mt-1">
                      <button onClick={showNewCompanyModal} type="button">
                        <PlusIcon className="h-5 w-10 bg-gray-700 text-white focus:outline-hidden" />
                      </button>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <CompanySelect
                      error={form.errors.company}
                      id="company"
                      isSubmitting={form.isSubmitting}
                      name="company"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      touched={form.touched.company}
                      value={form.values.company}
                    />
                  </div>
                </div>
              )
            }
          </div>

        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton
              disabled={form.isLoading}
              onClick={() => {
                handleHide(form);
              }}
            />

            <SubmitButton isSubmitting={form.isLoading} onClick={form.handleSubmit}>
              Speichern
            </SubmitButton>
          </div>
        </div>

      </form>
    </SlideOver>
  );
};

export default NewCompanyRoleSlide;
