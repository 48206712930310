import {
  Menu, MenuButton, MenuItem, MenuItems
} from "@headlessui/react";
import { Link } from "react-router-dom";

import API from "~/src/modules/api.js";

import useAuth from "~/src/hooks/use-auth.js";

import DropdownTransition from "~/src/ui/transitions/dropdown-transition/index.jsx";

const SettingsDropdown = () => {
  const {
    isAdmin, isMod, mutate, user
  } = useAuth();

  // TODO [2025-01-01]: replace this with better role
  const isChristoph = user?.email === "mayer@rsgroup.at";
  const isAnton = user?.email === "stiglitz@rsgroup.at";
  const isThomas = user?.email === "geyer@rsgroup.at";

  const handleLogout = async () => {
    await API.post("/auth/logout");
    await mutate();
    globalThis.location.replace("/login");
  };

  return (
    <div className="relative z-10 ml-3 flex items-center">
      <Menu>
        {({ open }) => (
          <>
            <MenuButton className="focus:outline-hidden inline-flex items-center justify-center rounded-md p-2 text-white hover:text-gray-200 focus:text-gray-100">
              <span className="sr-only">Open main menu</span>

              <svg aria-hidden="true" className="block size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path d="M4 6h16M4 12h16M4 18h16" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
              </svg>
            </MenuButton>

            <DropdownTransition open={open}>

              <MenuItems
                static
                modal={false}
              >
                <div aria-labelledby="user-menu" aria-orientation="vertical" className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5" role="menu">
                  <div className="border-b border-gray-300 px-4 pb-2 pt-1">
                    <p className="text-base font-medium text-gray-800">Benutzer</p>

                    <p className="text-sm font-medium text-gray-500">{user?.username}</p>
                  </div>

                  <div className="border-b border-gray-300 pb-1 xl:hidden">

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/automated-projects">
                        Automatisierte Projekte
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/projects">
                        Projekte
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/feed">
                        KV-Feed
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/companies">
                        Unternehmen
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/certificates">
                        ESG
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/activities">
                        Aktivitäten
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/contracts">
                        KV-Suche
                      </Link>
                    </MenuItem>

                    {(isChristoph || isAnton || isThomas)
                      ? (
                        <MenuItem>
                          <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/unit-buyer-differences">
                            Käufer-Differenzen
                          </Link>
                        </MenuItem>
                      )
                      : null}
                  </div>

                  {
                    isMod
                      ? (
                        <MenuItem>
                          <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/users">
                            Benutzerverwaltung
                          </Link>
                        </MenuItem>
                      )
                      : null
                  }

                  {
                    isAdmin
                      ? (
                        <MenuItem>
                          <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" to="/persons">
                            Personeninformationen
                          </Link>
                        </MenuItem>
                      )
                      : null
                  }

                  <MenuItem>
                    <Link className="focus:outline-hidden mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 sm:hidden" role="menuitem" to="/companies">
                      Unternehmen
                    </Link>
                  </MenuItem>

                  <MenuItem>
                    <button className="focus:outline-hidden flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleLogout} role="menuitem">
                      Logout
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </DropdownTransition>

          </>
        )}
      </Menu>
    </div>
  );
};

export default SettingsDropdown;
