import { useState } from "react";
import { useSWRConfig } from "swr";

import API from "~/src/modules/api.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";

const DeleteUserModal = ({
  hide, user, visible
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutate } = useSWRConfig();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await API.delete(`/users/${user.id}`);

      if (response.status === 200) {
        hide();
        await mutate("/users");
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal hide={hide} show={visible}>
      <div aria-labelledby="modal-headline" aria-modal="true" className="inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle" role="dialog">
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={hide} type="button">
            <span className="sr-only">hide</span>

            <svg aria-hidden="true" className="size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
            </svg>
          </button>
        </div>

        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <svg aria-hidden="true" className="size-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
            </svg>
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
              Benutzer löschen
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Sind Sie sicher, dass der Benutzer gelöscht werden soll?
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <SubmitButton className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-red-700 focus:outline-hidden focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" isSubmitting={isSubmitting} onClick={handleSubmit} type="button">
            Benutzer löschen
          </SubmitButton>

          <button className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-xs hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm" onClick={hide} type="button">
            Abbrechen
          </button>
        </div>
      </div>

    </Modal>
  );
};

export default DeleteUserModal;
